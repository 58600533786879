import { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { getPremium } from "../../../../../../actions/myihtpActions";
import Configs from "../../../../../../helpers/Configs";
import useCurrencies from "../../../../../../hooks/useCurrencies";
import Label, { Type } from "../../../../../Common/Label";

export default function ListagemPremios() {
  const [premios, setPremios] = useState<[]>([]);

  const currencies = useCurrencies();
  const intl = useIntl();

  useEffect(() => {
    getPremium().then(data => {
      if (!data.premios.error) {
        setPremios(data.premios.Premios);
      }
    });
  }, [])

  const listagempremios = useMemo(() => {
    const columns = [
      {
        Header: <FormattedMessage id="myihtp.premios.descricao" />,
        accessor: "Descricao",
        resizable: false,
        minWidth: 810
      },
      {
        Header: () => (
          <div>
            <FormattedMessage id="myihtp.premios.volume" />
          </div>
        ),
        accessor: "Valor",
        resizable: false,
        width: 170,
        Cell: row => {
          return (
            <Label
              text={row.original.Valor}
              labelType={{
                type: Type.currency,
                currency: currencies.selectedDescription
              }} />
          );
        }
      },
      {
        Header: () => (
          <div>
            <FormattedMessage id="myihtp.premios.quantofalta" />
          </div>
        ),
        accessor: "ValorRestante",
        resizable: false,
        width: 170,
        Cell: row => {
          return (
            <Label
              text={row.original.ValorRestante}
              labelType={{
                type: Type.currency,
                currency: currencies.selectedDescription
              }} />
          );
        }
      }
    ]

    return columns;
  }, []);

  return (
    <div>
      <hr />
      <ReactTable
        className="-highlight tableReact"
        data={premios}
        getTheadProps={(state, rowInfo, column) => {
          return {
            style: {
              fontWeight: Configs.myIHTP.tableConfig.fontHeightHeader,
              fontSize: Configs.myIHTP.tableConfig.fontSizeHeader,
              minHeight: Configs.myIHTP.tableConfig.heightHeader,
              color: Configs.myIHTP.tableConfig.colorHeader,
              backgroundColor:
                Configs.myIHTP.tableConfig.backgroundColorHeader
            }
          };
        }}
        getPaginationProps={state => {
          return {
            style: {
              color: Configs.myIHTP.tableConfig.colorHeader,
              backgroundColor:
                Configs.myIHTP.tableConfig.backgroundColorHeader,
              fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody
            }
          };
        }}
        getTrProps={(state, rowInfo, column, instance) => {
          return {
            style: {
              "text-align": "center",
              "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody
            }
          };
        }}
        getTheadThProps={(state, rowInfo, column, instance) => {
          return {
            style: {
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }
          };
        }}
        defaultPageSize={10}
        showPagination={false}
        previousText={Configs.myIHTP.tableConfig.buttonCopyAnterior}
        nextText={Configs.myIHTP.tableConfig.buttonCopyProximo}
        noDataText={Configs.myIHTP.tableConfig.noDataText}
        pageText={Configs.myIHTP.tableConfig.pageText}
        ofText={Configs.myIHTP.tableConfig.ofText}
        rowsText={intl.formatMessage({ id: "table.config.rowsText" })}
        columns={listagempremios}
      />
    </div>
  );
}