import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import "react-table/react-table.css";
import { getPremium } from "../../../../../../actions/myihtpActions";
import useCurrencies from "../../../../../../hooks/useCurrencies";
import Label, { Type } from "../../../../../Common/Label";

export default function ResumoPremios() {
  const [anoPassadoVolume, setAnoPassadoVolume] = useState<number>(0);
  const [anoCurrenteVolume, setAnoCurrenteVolume] = useState<number>(0);

  const currencies = useCurrencies();

  useEffect(() => {
    getPremium().then(data => {
      if (!data.premios.error) {
        setAnoPassadoVolume(data.premios.VolumeAnoPassado);
        setAnoCurrenteVolume(data.premios.VolumeAtual);
      }
    });
  }, [])

  return (
    <Container>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Row>
            <Col>
              <FormattedMessage id="myihtp.vales.resumoPremios.info1" />
              &nbsp;
              <Label
                text={anoPassadoVolume.toFixed(2)}
                labelType={{
                  type: Type.currency,
                  currency: currencies.selectedDescription
                }} />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormattedMessage id="myihtp.vales.resumoPremios.info2" />
              &nbsp;
              <Label
                text={anoCurrenteVolume.toFixed(2)}
                labelType={{
                  type: Type.currency,
                  currency: currencies.selectedDescription
                }} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}