import { useEffect, useMemo, useState } from "react";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { getAllLatestOrders, getOrderDetails } from "../../../../../../actions/myihtpActions";
import Configs from "../../../../../../helpers/Configs";
import useCurrencies from "../../../../../../hooks/useCurrencies";
import useUser from "../../../../../../hooks/useUser";
import Label, { Type } from "../../../../../Common/Label";
import OrderDetail, { OrderAddress, OrderState } from "../../../../../Common/Order/OrderDetail";

export interface OrderDetails {
  ID_TipoPagamento: string,
  DescricaoTipoPagamento: string,
  ID_Encomenda: number,
  PrecoFinal: number,
  ID_Moeda: string,
  EntidadeMB: string,
  ReferenciaMB: string,
  DataEncomenda: string,
  EstadoEncomenda: string,
  DescricaoTipoTransporte: string,
  DataPagamento: string,
  DataEnvio: string,
  moradaEnvioMorada: string,
  moradaEnvioNome: string,
  moradaEnvioCodigoPostal: string,
  moradaEnvioLocalidade: string,
  moradaEnvioPais: string,
  moradaFaturacaoMorada: string,
  moradaFaturacaoNome: string,
  moradaFaturacaoNif: string,
  moradaFaturacaoCodigoPostal: string,
  moradaFaturacaoLocalidade: string
  moradaFaturacaoPais: string,
  PrecoTotal: number,
  DescontoTotal: number,
  IvaTotal: number,
  CustoTransporte: number,
  DescontoCredito: number,
  Observacao: string,
  NetPointsPessoal: number
}

export interface OrderProductsDetails {
  Id: number,
  Descricao: string,
  TaxaIva: number,
  Quantidade: number,
  PrecoUnitarioSemIva: number,
  PrecoUnitarioComIva: number
}

export default function UltimasOrders() {
  const [latestOrders, setLatestOrders] = useState<[]>([]);
  const [details, setDetails] = useState<OrderDetails>({} as OrderDetails);
  const [itens, setItens] = useState<OrderProductsDetails[]>([]);
  const [estados, setEstados] = useState<[]>([]);
  const [expanded, setExpanded] = useState({});
  const [loadingDetails, setLoadingDetails] = useState<boolean>(false);

  const intl = useIntl();
  const user = useUser();
  const currencies = useCurrencies();

  useEffect(() => {
    getAllLatestOrders()
      .then(data => {
        if (!data.latestOrders.message) {
          setLatestOrders(data.latestOrders)
        }
      })
      .catch(error => {
        console.log(error);
      });
  }, [])

  const handleRowExpanded = (index) => {
    setExpanded({ [index[0]]: !expanded[index[0]] })
  }

  const getOrderDetailsData = (id, id_cliente) => async e => {
    setLoadingDetails(true);

    getOrderDetails(id, id_cliente)
      .then(data => {
        setLoadingDetails(false);

        if (data.success === true) {
          setDetails(data.obj.Detalhes[0]);
          setItens(data.obj.Itens);
          setEstados(data.obj.Estados);
        }

      })
      .catch(error => {
        setLoadingDetails(false);
        console.log(error);
      });
  };

  const getStates = (): OrderState[] => {
    if (estados == null || estados.length <= 0) return [];

    const result = estados.map((e: any) => {
      return {
        date: e.DataMudancaEstado,
        status: e.EstadoEncomenda,
        notes: e.Observacao,
        sentEmail: e.EnviadoMail
      }
    })

    return result;
  }

  const getShippingAddress = (): OrderAddress | undefined => {
    if (details.moradaEnvioMorada == null) return;

    return {
      address: details.moradaEnvioMorada,
      addressName: details.moradaEnvioNome,
      postalCode: details.moradaEnvioCodigoPostal,
      location: details.moradaEnvioLocalidade,
      country: details.moradaEnvioPais
    }
  }

  const ultimasencomendas = useMemo(() => {
    const columns = [
      {
        Header: "ID_Cliente",
        accessor: "ID_Cliente",
        show: false,
        resizable: false
      },
      {
        Header: (
          <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.table.id" />
        ),
        accessor: "ID_Encomenda",
        resizable: false,
        width: 85
      },
      {
        Header: (
          <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.table.dataEncomenda" />
        ),
        accessor: "DataEncomenda",
        resizable: false,
        width: 100,
        Cell: row => {
          return row.original.DataEncomenda ? (
            <FormattedDate value={new Date(row.original.DataEncomenda)} />
          ) : (
            "-"
          );
        }
      },
      {
        Header: (
          <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.table.estadoEncomenda" />
        ),
        accessor: "EstadoEncomenda",
        resizable: false
      },
      {
        Header: (
          <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.table.nome" />
        ),
        accessor: "NomeCompleto",
        resizable: false
      },
      {
        Header: (
          <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.table.nomeConsultor" />
        ),
        accessor: "NomeConsultor",
        resizable: false
      },
      {
        Header: (
          <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.table.precoFinal" />
        ),
        accessor: "PrecoFinal",
        resizable: false,
        width: 100,
        Cell: row => {
          const selectedCurrency = currencies.list?.find(a => a.ID_Moeda === row.original.ID_Moeda);

          return (
            <Label
              text={row.original.PrecoFinal.toFixed(2)}
              labelType={{
                type: Type.currency,
                currency: selectedCurrency?.Descricao ?? "€"
              }} />
          );
        }
      }
    ]

    return columns
  }, []);

  return (
    <div>
      <hr />
      <ReactTable
        className="-highlight tableReact"
        expanded={expanded}
        onExpandedChange={(newExpanded, index, event) =>
          handleRowExpanded(index)
        }
        data={latestOrders}
        getTheadProps={(state, rowInfo, column) => {
          return {
            style: {
              fontWeight: Configs.myIHTP.tableConfig.fontHeightHeader,
              fontSize: Configs.myIHTP.tableConfig.fontSizeHeader,
              height: Configs.myIHTP.tableConfig.heightHeader,
              color: Configs.myIHTP.tableConfig.colorHeader,
              backgroundColor:
                Configs.myIHTP.tableConfig.backgroundColorHeader
            }
          };
        }}
        getPaginationProps={state => {
          return {
            style: {
              color: Configs.myIHTP.tableConfig.colorHeader,
              backgroundColor:
                Configs.myIHTP.tableConfig.backgroundColorHeader,
              fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody
            }
          };
        }}
        getTrProps={(state, rowInfo, column, instance) => {
          var id_encomenda = null;
          var id_cliente = null;
          if (rowInfo) {
            id_encomenda = rowInfo.original.ID_Encomenda;
            id_cliente = rowInfo.original.ID_Cliente;
          }
          return {
            onClick: getOrderDetailsData(id_encomenda, id_cliente),
            style: {
              "text-align": "center",
              "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody
            }
          };
        }}
        defaultSorted={[
          {
            id: "ID_Encomenda",
            desc: true
          }
        ]}
        defaultPageSize={Configs.myIHTP.tableConfig.defaultPageSize}
        showPagination={
          latestOrders.length >
            Configs.myIHTP.tableConfig.defaultPageSize
            ? Configs.myIHTP.tableConfig.showPagination
            : false
        }
        previousText={Configs.myIHTP.tableConfig.buttonCopyAnterior}
        nextText={Configs.myIHTP.tableConfig.buttonCopyProximo}
        noDataText={Configs.myIHTP.tableConfig.noDataText}
        pageText={Configs.myIHTP.tableConfig.pageText}
        ofText={Configs.myIHTP.tableConfig.ofText}
        rowsText={intl.formatMessage({ id: "table.config.rowsText" })}
        columns={ultimasencomendas}
        SubComponent={row => {
          return (
            <OrderDetail
              loading={loadingDetails}
              orderId={row.original.ID_Encomenda}
              orderDate={details.DataEncomenda}
              state={details.EstadoEncomenda}
              transportTypeDescription={details.DescricaoTipoTransporte}
              paymentTypeId={details.ID_TipoPagamento}
              paymentTypeDescription={details.DescricaoTipoPagamento}
              paymentDate={details.DataPagamento}
              sentDate={details.DataEnvio}
              address={getShippingAddress()}
              billingAddress={{
                address: details.moradaFaturacaoMorada,
                addressName: details.moradaFaturacaoNome,
                vat: details.moradaFaturacaoNif,
                postalCode: details.moradaFaturacaoCodigoPostal,
                location: details.moradaFaturacaoLocalidade,
                country: details.moradaFaturacaoPais
              }}
              currency={details.ID_Moeda}
              totalPrice={details.PrecoTotal}
              totalDiscount={details.DescontoTotal}
              totalTax={details.IvaTotal}
              transportCost={details.CustoTransporte}
              userType={user.userType}
              creditDiscount={details.DescontoCredito}
              finalPrice={details.PrecoFinal}
              personalNetPoints={details.NetPointsPessoal}
              notes={details.Observacao}
              items={itens.map(i => {
                return {
                  id: i.Id,
                  description: i.Descricao,
                  taxPercentage: i.TaxaIva,
                  quantity: i.Quantidade,
                  unitPriceWithoutTax: i.PrecoUnitarioSemIva,
                  unitPriceWithTax: i.PrecoUnitarioComIva
                }
              })}
              states={getStates()}
              mbEntity={details.EntidadeMB}
              mbReference={details.ReferenciaMB}
            />
          );
        }}
      />
    </div>
  );
}