import { useEffect, useState } from "react";
// import Footer from "../../components/Footer/Footer";
import { Button, Col, Container, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { FormattedMessage } from "react-intl";
import { getOrderPayment } from "../../actions/miniCartActions";
import useCurrencies from "../../hooks/useCurrencies";
import Label, { Type } from "../Common/Label";
import HeaderDesktop from "../Desktop/Header/Header";
import Footer from "../Footer/Footer";
import DialogChangePaymentMethod from "./DialogChangePaymentMethod";
import "./Payment.css";
import PaymentCheque from "./PaymentCheque";
import PaymentEbanx from "./PaymentEbanx";
import PaymentMB from "./PaymentMB";
import PaymentMBWay from "./PaymentMBWay";
import PaymentPaypal from "./PaymentPaypal";
import PaymentTransferencia from "./PaymentTransferencia";
import PaymentCartaoCredito from './PaymentCartaoCredito';

interface PaymentInfo {
  EncomendaId: number,
  TipoPagamento: string,
  Moeda: string,
  MoradaEnvio: MoradaEnvio,
  MoradaFaturacao: MoradaFaturacao,
  MetodoEnvio: string,
  Descontos: number,
  Portes: number,
  Total: number,
  ValorPagar: number,
  EstadoEncomenda: string,
  ReferenciaMb: string,
  EntidadeMb: string,
  TipoTransporteId: string,
  EbanxHash: string,
  NumeroTelemovel: string,
  ValorConversao: number,
  UrlPagamentoCT: string,
}

interface MoradaEnvio {
  NomeMoradaEnvio: string,
  MoradaEnvio: string,
  CodigoPostalEnvio: string,
  LocalidadeEnvio: string
}

interface MoradaFaturacao {
  NomeMoradaFaturacao: string,
  MoradaFaturacao: string,
  LocalidadeFaturacao: string
  CodigoPostalFaturacao: string,
  Nif: string,
}


const paymentInfoInitialData: PaymentInfo = {
  EncomendaId: 0,
  TipoPagamento: "",
  Moeda: "EUR",
  MoradaEnvio: {
    NomeMoradaEnvio: "",
    MoradaEnvio: "",
    CodigoPostalEnvio: "",
    LocalidadeEnvio: ""
  },
  MoradaFaturacao: {
    NomeMoradaFaturacao: "",
    MoradaFaturacao: "",
    CodigoPostalFaturacao: "",
    LocalidadeFaturacao: "",
    Nif: ""
  },
  Descontos: 0,
  Portes: 0,
  Total: 0,
  ValorPagar: 0,
  EstadoEncomenda: "",
  ReferenciaMb: "",
  EntidadeMb: "",
  TipoTransporteId: "",
  MetodoEnvio: "",
  EbanxHash: "",
  NumeroTelemovel: "",
  ValorConversao: 0,
  UrlPagamentoCT: "",
}

export default function Payment() {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<string>("");
  const [paymentInfo, setPaymentInfo] = useState<PaymentInfo>(paymentInfoInitialData);

  const hash = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);

  const currencies = useCurrencies();
  const [orderCurrency, setOrderCurrency] = useState({
    ID_Moeda: "EUR",
    Descricao: "€"
  });
  const [valorConversao, setValorConversao] = useState<number>(1);

  const discountValue = paymentInfo.Descontos * valorConversao;
  var totalValue = paymentInfo.Total * valorConversao;
  var valueToPay = paymentInfo.ValorPagar * valorConversao;
  var deliveryCosts = paymentInfo.Portes * valorConversao;

  useEffect(() => {
    getOrderPayment(hash).then(data => {
      if (data.data.details) {
        const orderInfo = data.data.details[0];

        const payment: PaymentInfo = {
          EncomendaId: orderInfo.ID_Encomenda,
          TipoPagamento: orderInfo.ID_TipoPagamento,
          Moeda: orderInfo.ID_Moeda,
          MoradaEnvio: {
            NomeMoradaEnvio: orderInfo.nomeEnvio,
            MoradaEnvio: orderInfo.mEnvio,
            CodigoPostalEnvio: orderInfo.codigoPostalEnvio,
            LocalidadeEnvio: orderInfo.localidadeEnvio
          },
          MoradaFaturacao: {
            NomeMoradaFaturacao: orderInfo.nomeFaturacao,
            MoradaFaturacao: orderInfo.mFaturacao,
            CodigoPostalFaturacao: orderInfo.codigoPostalFaturacao,
            LocalidadeFaturacao: orderInfo.localidadeFaturacao,
            Nif: orderInfo.nifFaturacao
          },
          Descontos: orderInfo.DescontoTotal,
          Portes: orderInfo.CustoTransporte,
          Total: orderInfo.PrecoFinal,
          ValorPagar: orderInfo.PrecoFinal,
          EstadoEncomenda: orderInfo.ID_EstadoEncomenda,
          ReferenciaMb: orderInfo.ReferenciaMB,
          EntidadeMb: orderInfo.EntidadeMB,
          TipoTransporteId: orderInfo.ID_TipoTransporte,
          MetodoEnvio: orderInfo.descricaoTipoTransporte,
          EbanxHash: orderInfo.EbanxHash,
          NumeroTelemovel: orderInfo.NumeroTelemovelPagamento,
          ValorConversao: orderInfo.ValorConversao,
          UrlPagamentoCT: orderInfo.urlPagamentoCT,
        };

        setPaymentInfo(payment);
      }
    });
  }, [])

  useEffect(() => {
    let valorConversao = paymentInfo.ValorConversao;
    let currency = currencies?.list?.find(a => a.ID_Moeda === paymentInfo.Moeda);

    if (paymentInfo.Moeda === "EDR" && paymentInfo.TipoPagamento === "PP") {
      currency = {
        ID_Moeda: "EUR",
        Descricao: "€"
      };

      valorConversao = 1;
    }

    setValorConversao(valorConversao);
    setOrderCurrency(currency);

  }, [paymentInfo.Moeda, paymentInfo.TipoPagamento, paymentInfo.ValorConversao])


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: string) => {
    setOpen(false);
    setSelectedValue(value);
  };

  const updatePayment = () => {
    getOrderPayment(hash).then(data => {
      if (data.data.details) {
        const orderInfo = data.data.details[0];

        const payment: PaymentInfo = {
          EncomendaId: orderInfo.ID_Encomenda,
          TipoPagamento: orderInfo.ID_TipoPagamento,
          Moeda: orderInfo.ID_Moeda,
          MoradaEnvio: {
            NomeMoradaEnvio: orderInfo.nomeEnvio,
            MoradaEnvio: orderInfo.mEnvio,
            CodigoPostalEnvio: orderInfo.codigoPostalEnvio,
            LocalidadeEnvio: orderInfo.localidadeEnvio
          },
          MoradaFaturacao: {
            NomeMoradaFaturacao: orderInfo.nomeFaturacao,
            MoradaFaturacao: orderInfo.mFaturacao,
            CodigoPostalFaturacao: orderInfo.codigoPostalFaturacao,
            LocalidadeFaturacao: orderInfo.localidadeFaturacao,
            Nif: orderInfo.nifFaturacao
          },
          Descontos: orderInfo.DescontoTotal,
          Portes: orderInfo.CustoTransporte,
          Total: orderInfo.PrecoFinal,
          ValorPagar: orderInfo.PrecoFinal,
          EstadoEncomenda: orderInfo.ID_EstadoEncomenda,
          ReferenciaMb: orderInfo.ReferenciaMB,
          EntidadeMb: orderInfo.EntidadeMB,
          TipoTransporteId: orderInfo.ID_TipoTransporte,
          MetodoEnvio: orderInfo.descricaoTipoTransporte,
          EbanxHash: orderInfo.EbanxHash,
          NumeroTelemovel: orderInfo.NumeroTelemovelPagamento,
          ValorConversao: orderInfo.ValorConversao,
          UrlPagamentoCT: orderInfo.urlPagamentoCT,
        };

        setPaymentInfo(payment);
      }
    });
  };

  return (
    <div className="App">
      <HeaderDesktop />
      <br />
      <Container className="mainContentContainer">

        <br />
        <br />

        <div className="paymentFormatting"
          style={{
            marginLeft: isMobile ? "5%" : "20%",
            marginRight: isMobile ? "5%" : "20%"
          }}
        >
          <DialogChangePaymentMethod
            selectedValue={selectedValue}
            open={open}
            onClose={handleClose}
            encomendaID={paymentInfo.EncomendaId}
            hashEncomenda={hash}
            paymentType={paymentInfo.TipoPagamento}
            updatePaymentFunction={updatePayment}
          />
          <Row className="payment">
            <Col xs="12" sm="12" md="12" lg="12">
              <Row>
                <Col xs="12" sm="12" md="12" lg="12">
                  {paymentInfo.EncomendaId !== 0 ? (
                    paymentInfo.EstadoEncomenda === "AP" ||
                      paymentInfo.EstadoEncomenda === "PC" ? (
                      <div>
                        <Row>
                          <Col xs="12" sm="12" md="12" lg="12">
                            <h4 className="colorPink"><FormattedMessage id="pagamento.resumo.title" /></h4>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12" sm="12" md="12" lg="12">
                            <p>
                              <FormattedMessage id="pagamento.resumo.p1" />{" "}
                              <b>{paymentInfo.EncomendaId}</b>
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12" sm="12" md="12" lg="12">
                            <p>
                              <FormattedMessage id="pagamento.resumo.p2" />
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12" sm="12" md="12" lg="12">
                            <p>
                              <FormattedMessage id="pagamento.resumo.p3" />
                            </p>
                          </Col>
                        </Row>
                        <hr />

                        <Row style={{ display: isMobile ? "none" : "flex" }}>
                          {paymentInfo.TipoTransporteId !== "NA" ? (
                            <Col xs="12" sm="12" md="4" lg="4">
                              <h5 className="colorPink">
                                <FormattedMessage id="pagamento.resumo.moradaFaturacao.title" />
                              </h5>
                              {paymentInfo.MoradaFaturacao.NomeMoradaFaturacao} <br />
                              {paymentInfo.MoradaFaturacao.MoradaFaturacao} <br />
                              {paymentInfo.MoradaFaturacao.CodigoPostalFaturacao} <br />
                              {paymentInfo.MoradaFaturacao.LocalidadeFaturacao} <br />
                              {paymentInfo.MoradaFaturacao.Nif} <br />
                            </Col>
                          ) : (
                            <Col xs="12" sm="12" md="4" lg="12">
                              <h5 className="colorPink">
                                <FormattedMessage id="pagamento.resumo.moradaFaturacao.title" />
                              </h5>
                              {paymentInfo.MoradaFaturacao.NomeMoradaFaturacao} <br />
                              {paymentInfo.MoradaFaturacao.MoradaFaturacao} <br />
                              {paymentInfo.MoradaFaturacao.CodigoPostalFaturacao} <br />
                              {paymentInfo.MoradaFaturacao.LocalidadeFaturacao} <br />
                              {paymentInfo.MoradaFaturacao.Nif} <br />
                            </Col>
                          )}
                          {paymentInfo.TipoTransporteId !== "NA" ? (
                            <Col xs="12" sm="12" md="4" lg="4">
                              <h5 className="colorPink">
                                <FormattedMessage id="pagamento.resumo.moradaEnvio.title" />
                              </h5>
                              {paymentInfo.MoradaEnvio.NomeMoradaEnvio} <br />
                              {paymentInfo.MoradaEnvio.MoradaEnvio} <br />
                              {paymentInfo.MoradaEnvio.CodigoPostalEnvio} <br />
                              {paymentInfo.MoradaEnvio.LocalidadeEnvio} <br />
                            </Col>
                          ) : null}
                          {paymentInfo.TipoTransporteId !== "NA" ? (
                            <Col xs="12" sm="12" md="4" lg="4">
                              <h5 className="colorPink">
                                <FormattedMessage id="pagamento.resumo.metodoEnvio.title" />
                              </h5>
                              {paymentInfo.MetodoEnvio} <br />
                            </Col>
                          ) : null}
                        </Row>
                        {paymentInfo.TipoTransporteId !== "NA" ? (
                          <Row
                            style={{ display: isMobile ? "block" : "none" }}
                          >
                            <Col xs="12" sm="12" md="12" lg="4">
                              <h5 className="colorPink">
                                <FormattedMessage id="pagamento.resumo.moradaFaturacao.title" />
                              </h5>
                              {paymentInfo.MoradaFaturacao.NomeMoradaFaturacao} <br />
                              {paymentInfo.MoradaFaturacao.MoradaFaturacao} <br />
                              {paymentInfo.MoradaFaturacao.CodigoPostalFaturacao} <br />
                              {paymentInfo.MoradaFaturacao.LocalidadeFaturacao} <br />
                              {paymentInfo.MoradaFaturacao.Nif} <br />
                            </Col>
                            <hr
                              style={{ display: isMobile ? "block" : "none" }}
                            />
                          </Row>
                        ) : (
                          <Row
                            style={{ display: isMobile ? "block" : "none" }}
                          >
                            <Col xs="12" sm="12" md="12" lg="12">
                              <h5 className="colorPink">
                                <FormattedMessage id="pagamento.resumo.moradaFaturacao.title" />
                              </h5>
                              {paymentInfo.MoradaFaturacao.NomeMoradaFaturacao} <br />
                              {paymentInfo.MoradaFaturacao.MoradaFaturacao} <br />
                              {paymentInfo.MoradaFaturacao.CodigoPostalFaturacao} <br />
                              {paymentInfo.MoradaFaturacao.LocalidadeFaturacao} <br />
                              {paymentInfo.MoradaFaturacao.Nif} <br />
                            </Col>
                            <hr
                              style={{ display: isMobile ? "block" : "none" }}
                            />
                          </Row>
                        )}
                        {paymentInfo.TipoTransporteId !== "NA" ? (
                          <Row
                            style={{ display: isMobile ? "block" : "none" }}
                          >
                            <Col xs="12" sm="12" md="12" lg="4">
                              <h5 className="colorPink"><FormattedMessage id="pagamento.resumo.moradaEnvio.title" /></h5>
                              {paymentInfo.MoradaEnvio.NomeMoradaEnvio} <br />
                              {paymentInfo.MoradaEnvio.MoradaEnvio} <br />
                              {paymentInfo.MoradaEnvio.CodigoPostalEnvio} <br />
                              {paymentInfo.MoradaEnvio.LocalidadeEnvio} <br />
                            </Col>
                            <hr
                              style={{ display: isMobile ? "block" : "none" }}
                            />
                          </Row>
                        ) : null}
                        {paymentInfo.TipoTransporteId !== "NA" ? (
                          <Row
                            style={{ display: isMobile ? "block" : "none" }}
                          >
                            <Col xs="12" sm="12" md="12" lg="4">
                              <h5 className="colorPink"><FormattedMessage id="pagamento.resumo.metodoEnvio.title" /></h5>
                              {paymentInfo.MetodoEnvio} <br />
                            </Col>
                            <hr />
                          </Row>
                        ) : null}
                        <hr
                          style={{ display: isMobile ? "none" : "block" }}
                        />
                        <Row>
                          <Col xs="12" sm="12" md="12" lg="12">
                            <h5 className="colorPink">Total</h5>
                          </Col>
                          <Col xs="12" sm="12" md="3" lg="3" />
                          <Col xs="6" sm="6" md="3" lg="3" style={{ textAlign: "left" }}>
                            <FormattedMessage id="pagamento.resumo.descontos.title" />
                            <br />
                            <FormattedMessage id="pagamento.resumo.portes.title" />
                            <br />
                            <FormattedMessage id="pagamento.resumo.total.title" />
                            <br />
                            <FormattedMessage id="pagamento.resumo.valorAPagar.title" />
                          </Col>
                          <Col xs="6" sm="6" md="3" lg="3" style={{ textAlign: "right" }} >
                            <Label
                              text={discountValue.toFixed(2)}
                              labelType={{
                                type: Type.currency,
                                currency: orderCurrency?.Descricao
                              }} />
                            <br />
                            <Label
                              text={deliveryCosts.toFixed(2)}
                              labelType={{
                                type: Type.currency,
                                currency: orderCurrency?.Descricao
                              }} />
                            <br />
                            <Label
                              text={totalValue.toFixed(2)}
                              labelType={{
                                type: Type.currency,
                                currency: orderCurrency?.Descricao
                              }} />
                            <br />
                            <Label
                              text={valueToPay.toFixed(2)}
                              labelType={{
                                type: Type.currency,
                                currency: orderCurrency?.Descricao
                              }} />
                          </Col>
                          <Col xs="12" sm="12" md="3" lg="3" />
                        </Row>
                        <br />
                        <hr />
                        {paymentInfo.TipoPagamento !== "NA" ? (
                          <h5 className="colorPink"><FormattedMessage id="pagamento.resumo.pagamento.title" /></h5>
                        ) : null}

                        {(() => {
                          switch (paymentInfo.TipoPagamento) {
                            case "PP":
                              return (
                                <div>
                                  <PaymentPaypal
                                    encomenda={paymentInfo.EncomendaId}
                                    total={Number(valueToPay.toFixed(2))}
                                    currencyId={orderCurrency?.ID_Moeda}
                                  />
                                  <br />
                                </div>
                              );
                            case "EX":
                            case "EY":
                            case "EZ":
                              return (
                                <div>
                                  <PaymentEbanx
                                    hash={paymentInfo.EbanxHash}
                                  />
                                  <br />
                                </div>
                              );
                            case "TB":
                              return (
                                <div>
                                  <PaymentTransferencia
                                    total={valueToPay}
                                    currency={orderCurrency?.Descricao}
                                  />
                                  <br />
                                </div>
                              );
                            case "CH":
                              return (
                                <div>
                                  <PaymentCheque
                                    total={valueToPay}
                                    currency={orderCurrency?.Descricao}
                                  />
                                  <br />

                                </div>
                              );
                            case "MB":
                              return (
                                <div>
                                  <PaymentMB
                                    entidade={paymentInfo.EntidadeMb}
                                    referencia={paymentInfo.ReferenciaMb}
                                    total={valueToPay}
                                    currency={orderCurrency?.Descricao}
                                  />
                                  <br />
                                </div>
                              );
                            case "CT":
                              return (
                                <div>
                                  <PaymentCartaoCredito
                                    urlPagamentoCT={paymentInfo.UrlPagamentoCT}
                                  />
                                  <br />
                                </div>
                              );
                            case "MW":
                              return (
                                <div>
                                  <PaymentMBWay numtelemovel={paymentInfo.NumeroTelemovel} idEncomenda={paymentInfo.EncomendaId} />
                                  <br />
                                </div>
                              )
                            default:
                              return (
                                <div>
                                  <label><FormattedMessage id="pagamento.resumo.erro.metodoPagamentoInvalido" /></label>
                                </div>
                              );
                          }
                        })()}

                        {/* Método para alterar o tipo de pagamento da Encomenda*/}
                        <Row>
                          <Col xs={12} sm={12} md={12} lg={12}>
                            <span
                              className="fake-link"
                              id="fake-link-1"
                              onClick={handleClickOpen}>
                              <FormattedMessage id="pagamento.resumo.alterarTipoPagamento" />
                            </span>
                          </Col>
                        </Row>
                        <br />

                        {/* Método para ir para as suas encomendas*/}
                        <Row>
                          <Col xs={12} sm={12} md={12} lg={12}>
                            <Button
                              aria-label="Increment"
                              style={{
                                backgroundColor: "#ca006a",
                                border: "none",
                                minWidth: isMobile ? "50%" : "20%"
                              }}
                              href={process.env.REACT_APP_MY_WEBSITE_URL + "encomendas/"}>
                              <FormattedMessage id="pagamento.resumo.irParaEncomendas" />
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    ) : (
                      <div>
                        <Row>
                          <Col xs="12" sm="12" md="12" lg="12">
                            {" "}
                            <h4 className="colorPink"><FormattedMessage id="pagamento.resumo.title" /></h4>
                            <hr />
                            <p>
                              {/* A encomenda já foi processada {encomenda} */}
                              <b><FormattedMessage id="pagamento.resumo.encomendaJaProcessada" /></b>
                            </p>
                            <Button
                              aria-label="Increment"
                              style={{
                                backgroundColor: "#ca006a",
                                border: "none",
                                minWidth: isMobile ? "50%" : "20%"
                              }}
                              href={process.env.REACT_APP_MY_WEBSITE_URL + "encomendas/"}>
                              <FormattedMessage id="pagamento.resumo.irParaEncomendas" />
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    )
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Container >

      <Footer />
    </div >
  );
}