import { useEffect, useMemo, useState } from "react";
import { FormattedDate, FormattedHTMLMessage, FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { getAllComissions, getComissionsDetails, getOrderDetailsBackOffice } from "../../../../../../actions/myihtpActions";
import Configs from "../../../../../../helpers/Configs";
import useCurrencies from "../../../../../../hooks/useCurrencies";
import Label, { Type } from "../../../../../Common/Label";
import DialogEncomenda, { DetailsProps } from "../Helper/DialogEncomenda";
import InformationIcon from "../Helper/InformationIcon";

export default function ComissoesAnuais() {
  const [commissions, setCommissions] = useState<[]>([]);
  const [detailsComissions, setDetailsComissions] = useState<[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [expanded, setExpanded] = useState({});
  const [selectedValue, setSelectedValue] = useState<string>("");
  const [details, setDetails] = useState<DetailsProps>({} as DetailsProps);
  const [itens, setItens] = useState<[]>([]);
  const [estados, setEstados] = useState<[]>([]);

  const intl = useIntl();

  const currencies = useCurrencies();

  const comissoes = useMemo(() => {
    const columns = [
      {
        Header: <FormattedMessage id="myihtp.comissoes.ano" />,
        accessor: "Ano",
        resizable: false,
      },
      {
        Header: <FormattedMessage id="myihtp.comissoes.mes" />,
        accessor: "Mes",
        resizable: false,
      },
      {
        Header: <FormattedHTMLMessage id="myihtp.comparativoMensal.table.NPCAlias" />,
        accessor: "NPC",
        resizable: false,
      },
      {
        Header: <FormattedHTMLMessage id="myihtp.comparativoMensal.table.NPPAlias" />,
        accessor: "NPP",
        resizable: false,
      },
      {
        Header: <FormattedHTMLMessage id="myihtp.comparativoMensal.table.NPRAlias" />,
        accessor: "NPR",
        resizable: false,
      },
      {
        Header: <FormattedHTMLMessage id="myihtp.comparativoMensal.table.CRDAlias" />,
        accessor: "CRD",
        resizable: false,
      },
      {
        Header: <FormattedHTMLMessage id="myihtp.comparativoMensal.table.VCMAlias" />,
        accessor: "VCM",
        resizable: false,
        Cell: row => {
          return (
            <Label
              text={row.original.VCM.toFixed(2)}
              labelType={{
                type: Type.currency,
                currency: currencies.selectedDescription
              }} />
          );
        },
      },
      {
        Header: <FormattedHTMLMessage id="myihtp.comparativoMensal.table.NENAlias" />,
        accessor: "NEN",
        resizable: false,
      },
      {
        Header: <FormattedHTMLMessage id="myihtp.comparativoMensal.table.VENAlias" />,
        accessor: "VEN",
        resizable: false,
        Cell: row => {
          return (
            <Label
              text={row.original.VEN.toFixed(2)}
              labelType={{
                type: Type.currency,
                currency: currencies.selectedDescription
              }} />
          );
        },
      },
      {
        Header: <FormattedHTMLMessage id="myihtp.comparativoMensal.table.NPTAlias" />,
        accessor: "NPT",
        resizable: false,
      },
      {
        Header: <FormattedHTMLMessage id="myihtp.comparativoMensal.table.VTLAlias" />,
        accessor: "VTL",
        resizable: false,
        Cell: row => {
          return (
            <Label
              text={row.original.VTL.toFixed(2)}
              labelType={{
                type: Type.currency,
                currency: currencies.selectedDescription
              }} />
          );
        },
      },
    ]

    return columns;
  }, []);


  const detalheCom = useMemo(() => {
    const columns = [
      {
        Header: <FormattedMessage id="myihtp.comissoes.table.tipoComissao" />,
        accessor: "tipoCom",
        resizable: false,
      },
      {
        Header: <FormattedMessage id="myihtp.comissoes.table.data" />,
        accessor: "Data",
        resizable: false,
        Cell: row => {
          return <FormattedDate value={new Date(row.original.Data)} />;
        },
      },
      {
        Header: <FormattedMessage id="myihtp.comissoes.table.idEncomenda" />,
        accessor: "ID_Encomenda",
        resizable: false,
        Cell: row => {
          return (
            <span
              className="fake-link"
              id="fake-link-1"
              onClick={() => {
                handleClickOpen();
                getOrderDetailsData(row.original.ID_Encomenda);
              }}
            >
              {row.original.ID_Encomenda}{" "}
              {row.original.ID_Encomenda ? <InformationIcon /> : null}
            </span>
          );
        },
      },
      {
        Header: <FormattedMessage id="myihtp.comissoes.table.creditos" />,
        accessor: "Creditos",
        resizable: false,
        Cell: row => {
          return (
            <FormattedNumber
              value={row.original.Creditos ? row.original.Creditos : 0}
              style="decimal"
              minimumFractionDigits={2}
              maximumFractionDigits={2}
            />
          );
        },
      },
      {
        Header: <FormattedMessage id="myihtp.comissoes.table.netPointsPessoais" />,
        accessor: "NetPointsPessoal",
        resizable: false,
        Cell: row => {
          return (
            <FormattedNumber
              value={
                row.original.NetPointsPessoal
                  ? row.original.NetPointsPessoal
                  : 0
              }
              style="decimal"
              minimumFractionDigits={2}
              maximumFractionDigits={2}
            />
          );
        },
      },
      {
        Header: <FormattedMessage id="myihtp.comissoes.table.netPointsCliente" />,
        accessor: "NetPointsCliente",
        resizable: false,
        Cell: row => {
          return (
            <FormattedNumber
              value={
                row.original.NetPointsCliente
                  ? row.original.NetPointsCliente
                  : 0
              }
              style="decimal"
              minimumFractionDigits={2}
              maximumFractionDigits={2}
            />
          );
        },
      },
      {
        Header: <FormattedMessage id="myihtp.comissoes.table.netPointsRede" />,
        accessor: "NetPointsRede",
        resizable: false,
        Cell: row => {
          return (
            <FormattedNumber
              value={
                row.original.NetPointsRede ? row.original.NetPointsRede : 0
              }
              style="decimal"
              minimumFractionDigits={2}
              maximumFractionDigits={2}
            />
          );
        },
      },
      {
        Header: <FormattedMessage id="myihtp.comissoes.table.valorComissao" />,
        accessor: "ValorComissao",
        resizable: false,
        Cell: row => {
          return (
            <Label
              text={row.original.ValorComissao?.toFixed(2) ?? 0}
              labelType={{
                type: Type.currency,
                currency: currencies.selectedDescription
              }} />
          );
        },
      },
      {
        Header: <FormattedMessage id="myihtp.comissoes.table.observacoes" />,
        accessor: "Observacoes",
        resizable: false,
        Cell: row => {
          return (
            <span>
              {row.original.Observacoes ? row.original.Observacoes : "-"}
            </span>
          );
        },
      },
    ];

    return columns;
  }, []);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = value => {
    setOpen(false);
    setSelectedValue(value);
  };

  useEffect(() => {
    getAllComissions()
      .then(data => {
        if (!data.data.error) {
          setCommissions(data.data.ComissionsLastYear);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }, [])


  const getComissionsDetailsData = (mes, ano) => async e => {
    getComissionsDetails(mes, ano)
      .then(data => {
        setDetailsComissions(data.data.comissoes)
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleRowExpanded = (rowsState, index) => {
    setExpanded({ [index[0]]: !expanded[index[0]] })
  }

  const getOrderDetailsData = (id) => {
    getOrderDetailsBackOffice(id)
      .then(data => {
        if (data.success === true) {
          setDetails(data.obj.Detalhes[0]);
          setItens(data.obj.Itens);
          setEstados(data.obj.Estados);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  return (
    <div>
      <hr />
      <DialogEncomenda
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        details={details!}
        itens={itens}
        estados={estados}
      />
      <ReactTable
        className="-highlight tableReact"
        data={commissions}
        expanded={expanded}
        onExpandedChange={(newExpanded, index, event) =>
          handleRowExpanded(newExpanded, index)
        }
        getTheadProps={(state, rowInfo, column) => {
          return {
            style: {
              fontWeight: Configs.myIHTP.tableConfig.fontHeightHeader,
              fontSize: Configs.myIHTP.tableConfig.fontSizeHeader,
              height: Configs.myIHTP.tableConfig.heightHeader,
              color: Configs.myIHTP.tableConfig.colorHeader,
              backgroundColor:
                Configs.myIHTP.tableConfig.backgroundColorHeader,
            },
          };
        }}
        getPaginationProps={state => {
          return {
            style: {
              color: Configs.myIHTP.tableConfig.colorHeader,
              backgroundColor:
                Configs.myIHTP.tableConfig.backgroundColorHeader,
              fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody,
            },
          };
        }}
        getTrProps={(state, rowInfo, column, instance) => {
          var mes;
          var ano;
          if (rowInfo) {
            mes = rowInfo.original.Mes;
            ano = rowInfo.original.Ano;
          }
          return {
            onClick: getComissionsDetailsData(mes, ano),
            style: {
              "text-align": "center",
              "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody,
            },
          };
        }}
        defaultPageSize={Configs.myIHTP.tableConfig.defaultPageSize}
        showPagination={
          commissions.length >
            Configs.myIHTP.tableConfig.defaultPageSize
            ? Configs.myIHTP.tableConfig.showPagination
            : false
        }
        previousText={Configs.myIHTP.tableConfig.buttonCopyAnterior}
        nextText={Configs.myIHTP.tableConfig.buttonCopyProximo}
        noDataText={Configs.myIHTP.tableConfig.noDataText}
        pageText={Configs.myIHTP.tableConfig.pageText}
        ofText={Configs.myIHTP.tableConfig.ofText}
        rowsText={intl.formatMessage({ id: "table.config.rowsText" })}
        columns={comissoes}
        // SubComponent={row => <span>TESTE</span>}
        SubComponent={row => {
          return (
            <div
              style={{
                padding: "10px",
                backgroundColor: "#f8f9fa",
              }}
            >
              <ReactTable
                getTheadProps={(state, rowInfo, column) => {
                  return {
                    style: {
                      color: "black",
                      textAlign: "center",
                      backgroundColor: "#ffe4c4",
                    },
                  };
                }}
                getTrProps={(state, rowInfo, column, instance) => {
                  return {
                    style: {
                      "text-align": "center",
                      "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody,
                    },
                  };
                }}
                getPaginationProps={state => {
                  return {
                    style: {
                      color: Configs.myIHTP.tableConfig.colorHeader,
                      backgroundColor:
                        Configs.myIHTP.tableConfig.backgroundColorHeader,
                      fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody,
                    },
                  };
                }}
                data={detailsComissions}
                columns={detalheCom}
                showPagination={Configs.myIHTP.tableConfig.showPagination}
                defaultPageSize={Configs.myIHTP.tableConfig.defaultPageSize}
              />
            </div>
          );
        }}
      />
    </div>
  );
}