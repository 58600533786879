import { Component } from "react";

import { FormattedMessage } from "react-intl";
import ComparativoAnualDashboard from "./ComparativoAnualDashboard/ComparativoAnualDashboard";
import ComparativoMensalDashboard from "./ComparativoMensalDashboard/ComparativoMensalDashboard";
import PrevisaoMesAtual from "./PrevisaoMesAtual/PrevisaoMesAtual";

class IndicadoresDashboard extends Component {
  render() {
    return (
      <div className="myaccount-content">
        <PrevisaoMesAtual />
        <hr />

        <b>
          <FormattedMessage id="myihtp.comparativomensal.title" />
        </b>
        <ComparativoMensalDashboard />
        <hr />

        <b>
          <FormattedMessage id="myihtp.comparativoanual.title" />
        </b>
        <ComparativoAnualDashboard />
      </div>
    );
  }
}
export default IndicadoresDashboard;