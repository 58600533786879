import { useEffect, useMemo, useState } from "react";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Label } from "reactstrap";
import { getPayments } from "../../../../../../actions/myihtpActions";
import Configs from "../../../../../../helpers/Configs";
import useCurrencies from "../../../../../../hooks/useCurrencies";
import { Type } from "../../../../../Common/Label";

export default function Pagamentos() {
  const [pagamentos, setPagamentos] = useState<[]>([]);

  const currencies = useCurrencies();
  const intl = useIntl();

  const columns = useMemo(() => {
    const columns = [
      {
        Header: <FormattedMessage id="myihtp.comissoes.pagamentos.data" />,
        accessor: "DataPagamento",
        resizable: false,
        Cell: row => {
          return (
            <FormattedDate value={new Date(row.original.DataPagamento)} />
          );
        }
      },
      {
        Header: (
          <FormattedMessage id="myihtp.comissoes.pagamentos.tipopagamento" />
        ),
        accessor: "ID_TipoPagamento",
        resizable: false
      },
      {
        Header: (
          <FormattedMessage id="myihtp.comissoes.pagamentos.valorpago" />
        ),
        accessor: "ValorPago",
        resizable: false,
        Cell: row => {
          return (
            <Label
              text={row.original.ValorPago.toFixed(2)}
              labelType={{
                type: Type.currency,
                currency: currencies.selectedDescription
              }} />
          );
        }
      },
      {
        Header: (
          <FormattedMessage id="myihtp.comissoes.pagamentos.observacoes" />
        ),
        accessor: "Observacao",
        resizable: false
      }
    ]

    return columns;
  }, []);


  useEffect(() => {
    getPayments().then(data => {
      if (!data.data.error) {
        setPagamentos(data.data.Pagamentos);
      }
    });
  }, [])

  return (
    <div>
      <hr />
      <ReactTable
        className="-highlight tableReact"
        data={pagamentos}
        getTheadProps={(state, rowInfo, column) => {
          return {
            style: {
              fontWeight: Configs.myIHTP.tableConfig.fontHeightHeader,
              fontSize: Configs.myIHTP.tableConfig.fontSizeHeader,
              height: Configs.myIHTP.tableConfig.heightHeader,
              color: Configs.myIHTP.tableConfig.colorHeader,
              backgroundColor:
                Configs.myIHTP.tableConfig.backgroundColorHeader
            }
          };
        }}
        getPaginationProps={state => {
          return {
            style: {
              color: Configs.myIHTP.tableConfig.colorHeader,
              backgroundColor:
                Configs.myIHTP.tableConfig.backgroundColorHeader,
              fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody
            }
          };
        }}
        getTrProps={(state, rowInfo, column, instance) => {
          return {
            style: {
              "text-align": "center",
              "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody
            }
          };
        }}
        defaultPageSize={Configs.myIHTP.tableConfig.defaultPageSize}
        showPagination={
          pagamentos.length >
            Configs.myIHTP.tableConfig.defaultPageSize
            ? Configs.myIHTP.tableConfig.showPagination
            : false
        }
        previousText={Configs.myIHTP.tableConfig.buttonCopyAnterior}
        nextText={Configs.myIHTP.tableConfig.buttonCopyProximo}
        noDataText={Configs.myIHTP.tableConfig.noDataText}
        pageText={Configs.myIHTP.tableConfig.pageText}
        ofText={Configs.myIHTP.tableConfig.ofText}
        rowsText={intl.formatMessage({ id: "table.config.rowsText" })}
        columns={columns}
      />
    </div>
  );
}