import { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { getCurrentMonthForecastRede, getForecastRede } from "../../../../../../actions/myihtpActions";
import Configs from "../../../../../../helpers/Configs";
import useCurrencies from "../../../../../../hooks/useCurrencies";
import Label, { Type } from "../../../../../Common/Label";


export default function PrevisoesRede() {
  const [forecastRede, setForecastRede] = useState<[]>([]);
  const [monthForecast, setMonthForecast] = useState<[]>([]);
  const [expanded, setExpanded] = useState({});
  const [loadingDetail, setLoadingDetail] = useState<boolean>(false);
  const [currentClientDetail, setCurrentClientDetail] = useState<number>();

  const currencies = useCurrencies();
  const intl = useIntl();

  const handleRowExpanded = (index) => {
    setExpanded({ [index[0]]: !expanded[index[0]] })
  }

  useEffect(() => {
    getForecastRede().then(data => {
      if (
        data.forecastRede.message !== "Não tem registos disponiveis." &&
        !data.forecastRede.error
      ) {
        setForecastRede(data.forecastRede);
      }
    })
  }, []);

  const getMonthForecastRede = (id_cliente) => async e => {
    //Se o id do cliente que queremos ver o detalhe é igual ao que está aberto, não fazemos nada
    if (id_cliente == currentClientDetail) {
      return;
    }

    setLoadingDetail(true);
    setCurrentClientDetail(id_cliente);

    getCurrentMonthForecastRede(id_cliente)
      .then(data => {
        setLoadingDetail(false);

        if (data.success == true) {
          setMonthForecast(data.obj);
        } else {
          setMonthForecast([]);
        }
      })
      .catch(error => {
        setMonthForecast([]);
        setLoadingDetail(false);
      });
  };

  const businessCircleState = (state) => {
    if (state < 0.25) {
      return (
        <span>
          <svg
            height="15px"
            viewBox="0 0 512 512.00115"
            width="15px"
            xmlns="http://www.w3.org/2000/svg"
            style={{ fill: "#FF0000" }}
          >
            <path d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z" />
          </svg>
        </span>
      );
    } else if (state >= 0.25 && state < 0.5) {
      return (
        <span>
          <svg
            height="15px"
            viewBox="0 0 512 512.00115"
            width="15px"
            xmlns="http://www.w3.org/2000/svg"
            style={{ fill: "#FC8701" }}
          >
            <path d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z" />
          </svg>
        </span>
      );
    } else if (state >= 0.5 && state < 0.75) {
      return (
        <span>
          <svg
            height="15px"
            viewBox="0 0 512 512.00115"
            width="15px"
            xmlns="http://www.w3.org/2000/svg"
            style={{ fill: "#FCFF00" }}
          >
            <path d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z" />
          </svg>
        </span>
      );
    } else if (state >= 0.75 && state < 1) {
      return (
        <span>
          <svg
            height="15px"
            viewBox="0 0 512 512.00115"
            width="15px"
            xmlns="http://www.w3.org/2000/svg"
            style={{ fill: "#1600FF" }}
          >
            <path d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z" />
          </svg>
        </span>
      );
    } else if (state >= 1) {
      return (
        <span>
          <svg
            height="15px"
            viewBox="0 0 512 512.00115"
            width="15px"
            xmlns="http://www.w3.org/2000/svg"
            style={{ fill: "#01FF01" }}
          >
            <path d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z" />
          </svg>
        </span>
      );
    }
  }

  const previsaomensalatual = useMemo(() => {
    const columns = [
      {
        Header: "ID_Forecast",
        accessor: "ID_Forecast",
        show: false,
        resizable: false,
      },
      {
        Header: <FormattedMessage id="myihtp.previsaoMesAtual.table.Ano" />,
        accessor: "Ano",
        width: 50,
        resizable: false,
      },
      {
        Header: <FormattedMessage id="myihtp.previsaoMesAtual.table.Mes" />,
        accessor: "Mes",
        width: 50,
        resizable: false,
      },
      {
        Header: "Volume Pessoal",
        accessor: "VolumePessoal_P",
        show: false,
        resizable: false,
        Cell: row => {
          return (
            <Label
              text={row.original.VolumePessoal_P.toFixed(2)}
              labelType={{
                type: Type.currency,
                currency: currencies.selectedDescription
              }} />
          );
        },
      },
      {
        Header: "Volume Pessoal R",
        accessor: "VolumePessoal_R",
        show: false,
        resizable: false,
        Cell: row => {
          return (
            <Label
              text={row.original.VolumePessoal_R.toFixed(2)}
              labelType={{
                type: Type.currency,
                currency: currencies.selectedDescription
              }} />
          );
        },
      },
      {
        Header: "Volume Pessoal Percentagem Alcancada",
        accessor: "VolumePessoalPercentagemAlcancada",
        show: false,
        resizable: false,
        Cell: row => {
          return (
            <Label
              text={row.original.VolumePessoalPercentagemAlcancada.toFixed(2)}
              labelType={{
                type: Type.currency,
                currency: currencies.selectedDescription
              }} />
          );
        },
      },
      {
        Header: (
          <FormattedMessage id="myihtp.previsaoMesAtual.table.VolumesPessoais" />
        ),
        filterable: false,
        sortable: false,
        Cell: row => {
          return (
            <Row>
              <Col
                xs="12"
                sm="12"
                md="12"
                lg="12"
                style={{ textAlign: "center" }}
              >
                <Row>
                  <Col
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    style={{ textAlign: "center" }}
                  >
                    <Label
                      text={row.original.VolumePessoal_P.toFixed(2)}
                      labelType={{
                        type: Type.currency,
                        currency: currencies.selectedDescription
                      }} />
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col
                    xs="12"
                    sm="12"
                    md="4"
                    lg="4"
                    style={{ textAlign: "center" }}
                  >
                    <Label
                      text={row.original.VolumePessoal_R.toFixed(2)}
                      labelType={{
                        type: Type.currency,
                        currency: currencies.selectedDescription
                      }} />
                  </Col>
                  <Col
                    xs="12"
                    sm="12"
                    md="4"
                    lg="4"
                    style={{ textAlign: "center" }}
                  >
                    {businessCircleState(row.row.VolumePessoalPercentagemAlcancada)}
                  </Col>
                  <Col
                    xs="12"
                    sm="12"
                    md="4"
                    lg="4"
                    style={{ textAlign: "center" }}
                  >
                    <FormattedNumber
                      value={row.original.VolumePessoalPercentagemAlcancada}
                      style="percent"
                      minimumFractionDigits={2}
                      maximumFractionDigits={2}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          );
        },
        resizable: false,
      },
      {
        Header: "Volume Kits",
        accessor: "VolumeKits_P",
        show: false,
        resizable: false,
      },
      {
        Header: "Volume Kits R",
        accessor: "VolumeKits_R",
        show: false,
        resizable: false,
      },
      {
        Header: "Volume Kits Percentagem Alcancada",
        accessor: "VolumeKitsPercentagemAlcancada",
        show: false,
        resizable: false,
      },
      {
        Header: (
          <FormattedMessage id="myihtp.previsaoMesAtual.table.VolumesKits" />
        ),
        filterable: false,
        sortable: false,
        Cell: row => {
          return (
            <Row>
              <Col
                xs="12"
                sm="12"
                md="12"
                lg="12"
                style={{ textAlign: "center" }}
              >
                <Row>
                  <Col
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    style={{ textAlign: "center" }}
                  >
                    <Label
                      text={row.original.VolumeKits_P.toFixed(2)}
                      labelType={{
                        type: Type.currency,
                        currency: currencies.selectedDescription
                      }} />
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col
                    xs="12"
                    sm="12"
                    md="4"
                    lg="4"
                    style={{ textAlign: "center" }}
                  >
                    <Label
                      text={row.original.VolumeKits_R.toFixed(2)}
                      labelType={{
                        type: Type.currency,
                        currency: currencies.selectedDescription
                      }} />
                  </Col>
                  <Col
                    xs="12"
                    sm="12"
                    md="4"
                    lg="4"
                    style={{ textAlign: "center" }}
                  >
                    {businessCircleState(row.row.VolumeKitsPercentagemAlcancada)}
                  </Col>
                  <Col
                    xs="12"
                    sm="12"
                    md="4"
                    lg="4"
                    style={{ textAlign: "center" }}
                  >
                    <FormattedNumber
                      value={row.original.VolumeKitsPercentagemAlcancada}
                      style="percent"
                      minimumFractionDigits={2}
                      maximumFractionDigits={2}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          );
        },
        resizable: false,
      },
      {
        Header: "Volume Clientes",
        accessor: "VolumeClientes_P",
        show: false,
        resizable: false,
      },
      {
        Header: "Volume Clientes R",
        accessor: "VolumeClientes_R",
        show: false,
        resizable: false,
      },
      {
        Header: "Volume Clientes Percentagem Alcancada",
        accessor: "VolumeClientesPercentagemAlcancada",
        show: false,
        resizable: false,
      },
      {
        Header: (
          <FormattedMessage id="myihtp.previsaoMesAtual.table.VolumesClientes" />
        ),
        filterable: false,
        sortable: false,
        Cell: row => {
          return (
            <Row>
              <Col
                xs="12"
                sm="12"
                md="12"
                lg="12"
                style={{ textAlign: "center" }}
              >
                <Row>
                  <Col
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    style={{ textAlign: "center" }}
                  >
                    <Label
                      text={row.original.VolumeClientes_P.toFixed(2)}
                      labelType={{
                        type: Type.currency,
                        currency: currencies.selectedDescription
                      }} />
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col
                    xs="12"
                    sm="12"
                    md="4"
                    lg="4"
                    style={{ textAlign: "center" }}
                  >
                    <Label
                      text={row.original.VolumeClientes_R.toFixed(2)}
                      labelType={{
                        type: Type.currency,
                        currency: currencies.selectedDescription
                      }} />
                  </Col>
                  <Col
                    xs="12"
                    sm="12"
                    md="4"
                    lg="4"
                    style={{ textAlign: "center" }}
                  >
                    {businessCircleState(row.row.VolumeClientesPercentagemAlcancada)}
                  </Col>
                  <Col
                    xs="12"
                    sm="12"
                    md="4"
                    lg="4"
                    style={{ textAlign: "center" }}
                  >
                    <FormattedNumber
                      value={row.original.VolumeClientesPercentagemAlcancada}
                      style="percent"
                      minimumFractionDigits={2}
                      maximumFractionDigits={2}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          );
        },
        resizable: false,
      },
      {
        Header: "Totais",
        accessor: "Totais_P",
        show: false,
        resizable: false,
      },
      {
        Header: "Totais R",
        accessor: "Totais_R",
        show: false,
        resizable: false,
      },
      {
        Header: "Total Percentagem Alcancada",
        accessor: "TotalPercentagemAlcancada",
        show: false,
        resizable: false,
      },
      {
        Header: (
          <FormattedMessage id="myihtp.previsaoMesAtual.table.Totais" />
        ),
        filterable: false,
        sortable: false,
        Cell: row => {
          return (
            <Row>
              <Col
                xs="12"
                sm="12"
                md="12"
                lg="12"
                style={{ textAlign: "center" }}
              >
                <Row>
                  <Col
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    style={{ textAlign: "center" }}
                  >
                    <Label
                      text={row.original.Totais_P.toFixed(2)}
                      labelType={{
                        type: Type.currency,
                        currency: currencies.selectedDescription
                      }} />
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col
                    xs="12"
                    sm="12"
                    md="4"
                    lg="4"
                    style={{ textAlign: "center" }}
                  >
                    <Label
                      text={row.original.Totais_R.toFixed(2)}
                      labelType={{
                        type: Type.currency,
                        currency: currencies.selectedDescription
                      }} />
                  </Col>
                  <Col
                    xs="12"
                    sm="12"
                    md="4"
                    lg="4"
                    style={{ textAlign: "center" }}
                  >
                    {businessCircleState(row.row.TotalPercentagemAlcancada)}
                  </Col>
                  <Col
                    xs="12"
                    sm="12"
                    md="4"
                    lg="4"
                    style={{ textAlign: "center" }}
                  >
                    <FormattedNumber
                      value={row.original.TotalPercentagemAlcancada}
                      style="percent"
                      minimumFractionDigits={2}
                      maximumFractionDigits={2}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          );
        },
        resizable: false,
      },
    ]

    return columns;
  }, []);


  const columnsLastRecords = useMemo(() => {
    const column = [
      {
        Header: <FormattedMessage id="myihtp.previsaoRede.table.nomeConsultor" />,
        accessor: "NomeCompleto",
        resizable: false,
      }
    ]

    return column;
  }, []);

  return (
    <div>
      <hr />
      <ReactTable
        className="-highlight tableReact"
        data={forecastRede}
        expanded={expanded}
        onExpandedChange={(newExpanded, index, event) =>
          handleRowExpanded(index)
        }
        getTheadProps={(state, rowInfo, column) => {
          return {
            style: {
              fontWeight: Configs.myIHTP.tableConfig.fontHeightHeader,
              fontSize: Configs.myIHTP.tableConfig.fontSizeHeader,
              height: Configs.myIHTP.tableConfig.heightHeader,
              color: Configs.myIHTP.tableConfig.colorHeader,
              backgroundColor:
                Configs.myIHTP.tableConfig.backgroundColorHeader,
            },
          };
        }}
        getPaginationProps={state => {
          return {
            style: {
              color: Configs.myIHTP.tableConfig.colorHeader,
              backgroundColor:
                Configs.myIHTP.tableConfig.backgroundColorHeader,
              fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody,
            },
          };
        }}
        getTrProps={(state, rowInfo, column, instance) => {
          var id_cliente = null;

          if (rowInfo) {
            id_cliente = rowInfo.original.ID_Cliente;
          }
          return {
            onClick: getMonthForecastRede(id_cliente),
            style: {
              "text-align": "center",
              "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody,
            },
          };
        }}
        defaultPageSize={Configs.myIHTP.tableConfig.defaultPageSize}
        showPagination={
          forecastRede.length >
            Configs.myIHTP.tableConfig.defaultPageSize
            ? Configs.myIHTP.tableConfig.showPagination
            : false
        }
        previousText={Configs.myIHTP.tableConfig.buttonCopyAnterior}
        nextText={Configs.myIHTP.tableConfig.buttonCopyProximo}
        noDataText={Configs.myIHTP.tableConfig.noDataText}
        pageText={Configs.myIHTP.tableConfig.pageText}
        ofText={Configs.myIHTP.tableConfig.ofText}
        rowsText={intl.formatMessage({ id: "table.config.rowsText" })}
        columns={columnsLastRecords}
        SubComponent={row => {
          return (
            <div
              style={{
                fontSize: "14px",
                padding: "10px",
                backgroundColor: "#f8f9fa",
              }}
            >
              <ReactTable
                className="-highlight  tableReact"
                data={monthForecast}
                loading={loadingDetail}
                getTheadProps={(state, rowInfo, column) => {
                  return {
                    style: {
                      fontSize: Configs.myIHTP.tableConfig.fontSizeHeader,
                      color: "black",
                      backgroundColor: "rgb(255, 228, 196)",
                    },
                  };
                }}
                getPaginationProps={state => {
                  return {
                    style: {
                      color: Configs.myIHTP.tableConfig.colorHeader,
                      backgroundColor:
                        Configs.myIHTP.tableConfig.backgroundColorHeader,
                      fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody,
                    },
                  };
                }}
                getTrProps={(state, rowInfo, column, instance) => {
                  return {
                    style: {
                      "text-align": "center",
                      "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody,
                      "align-items": "center",
                    },
                  };
                }}
                defaultPageSize={Configs.myIHTP.tableConfig.defaultPageSize}
                showPagination={
                  monthForecast.length >
                    Configs.myIHTP.tableConfig.defaultPageSize
                    ? Configs.myIHTP.tableConfig.showPagination
                    : false
                }
                previousText={Configs.myIHTP.tableConfig.buttonCopyAnterior}
                nextText={Configs.myIHTP.tableConfig.buttonCopyProximo}
                noDataText={Configs.myIHTP.tableConfig.noDataText}
                pageText={Configs.myIHTP.tableConfig.pageText}
                ofText={Configs.myIHTP.tableConfig.ofText}
                rowsText={intl.formatMessage({ id: "table.config.rowsText" })}
                columns={previsaomensalatual}
              />
            </div>
          );
        }}
      />
    </div>
  );
}