import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { CSSProperties, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { FormattedDate, FormattedMessage, FormattedNumber } from "react-intl";
import ReactTable from "react-table";
import Configs from "../../../../../../helpers/Configs";
import useCurrencies from "../../../../../../hooks/useCurrencies";
import Label, { Type } from "../../../../../Common/Label";

export interface DetailsProps {
  ID_TipoPagamento: string,
  DescricaoTipoPagamento: string,
  ID_Encomenda: number,
  PrecoFinal: number,
  ID_Moeda: string,
  EntidadeMB: string,
  ReferenciaMB: string,
  DataEncomenda: string,
  EstadoEncomenda: string,
  DescricaoTipoTransporte: string,
  DataPagamento: string,
  DataEnvio: string,
  moradaEnvioMorada: string,
  moradaEnvioNome: string,
  moradaEnvioCodigoPostal: string,
  moradaEnvioLocalidade: string,
  moradaEnvioPais: string,
  moradaFaturacaoMorada: string,
  moradaFaturacaoNome: string,
  moradaFaturacaoNif: string,
  moradaFaturacaoCodigoPostal: string,
  moradaFaturacaoLocalidade: string
  moradaFaturacaoPais: string,
  PrecoTotal: number,
  DescontoTotal: number,
  IvaTotal: number,
  CustoTransporte: number,
  DescontoCredito: number,
  Observacao: string
}

interface DialogEncomendaProps {
  selectedValue: string;
  open: boolean;
  onClose: (value: string) => void;
  details: DetailsProps;
  itens: []
  estados: []
}

export default function DialogEncomenda(props: DialogEncomendaProps) {
  const currencies = useCurrencies();

  const selectedCurrency = currencies.list?.find(a => a.ID_Moeda === props.details.ID_Moeda)?.Descricao;

  const handleClose = () => {
    props.onClose(props.selectedValue);
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        style={{ minHeight: "100%" }}
        fullWidth={true}
        maxWidth={"md"}
        open={props.open}>
        <Container>
          <Row>
            <Col xs="10" sm="10" md="10" lg="10">
              <DialogTitle id="simple-dialog-title">
                <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.detalheEncomenda.title" />
              </DialogTitle>
            </Col>
            <Col
              xs="2"
              sm="2"
              md="2"
              lg="2"
              onClick={handleClose}
              style={{
                textAlign: "right",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <svg
                width="33px"
                height="33px"
                viewBox="0 0 33 33"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  id="Menu"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g
                    id="MD"
                    transform="translate(-1311.000000, -21.000000)"
                    fill="#000000"
                  >
                    <g
                      id="close-(1)"
                      transform="translate(1311.000000, 21.000000)"
                    >
                      <path
                        d="M32.6096072,0.390441109 C32.0890191,-0.130147036 31.245005,-0.130147036 30.7244169,0.390441109 L0.390441109,30.7243524 C-0.130147036,31.2449405 -0.130147036,32.0889546 0.390441109,32.6095428 C0.650702954,32.8699335 0.991917965,33 1.33306852,33 C1.67421908,33 2.01536964,32.8698691 2.27569594,32.6094783 L32.6096072,2.27556703 C33.1301309,1.75504334 33.1301309,0.911029253 32.6096072,0.390441109 Z"
                        id="Path"
                      />
                      <path
                        d="M32.6095985,30.7243524 L2.27557092,0.390441109 C1.75504634,-0.130147036 0.910966357,-0.130147036 0.390441776,0.390441109 C-0.130147259,0.9109648 -0.130147259,1.75497888 0.390441776,2.27556703 L30.7244694,32.6095428 C30.9847317,32.8698691 31.3259472,33 31.6670984,33 C32.0082495,33 32.3494651,32.8698691 32.609663,32.6096072 C33.1301231,32.0889546 33.1301231,31.2449405 32.6095985,30.7243524 Z"
                        id="Path"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </Col>
          </Row>
          <div
            style={{
              fontSize: "14px",
              padding: "10px",
              backgroundColor: "#f8f9fa",
            }}
          >
            <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleTitle as CSSProperties}
            >
              {Configs.myIHTP.tableConfig.orderDetails.title}
            </p>
            {props.details.ID_TipoPagamento === "MB" ? (
              <Row>
                <Col
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  style={{ textAlign: "center" }}
                >
                  <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleSubtitle as CSSProperties}>
                    <FormattedMessage id="myihtp.encomendas.DadosPagamento.Titulo" />
                  </p>
                  <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleData as CSSProperties} />
                  <Row>
                    <Col
                      xs="12"
                      sm="12"
                      md="6"
                      lg="6"
                      style={{
                        textAlign: isMobile ? "center" : "right",
                      }}
                    >
                      <img src="https://my.ihavethepower.net/Content/imagens/emails/multibanco.png" />
                    </Col>
                    <Col
                      xs="12"
                      sm="12"
                      md="6"
                      lg="6"
                      style={{
                        textAlign: isMobile ? "center" : "left",
                        margin: "auto",
                        top: "0px",
                        bottom: "0px",
                      }}
                    >
                      <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleData2 as CSSProperties}>
                        <b><FormattedMessage id="myihtp.encomendas.DadosPagamento.mb.Entidade" />:</b> {props.details.EntidadeMB}
                      </p>
                      <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleData2 as CSSProperties}>
                        <b><FormattedMessage id="myihtp.encomendas.DadosPagamento.mb.Referencia" />:</b> {props.details.ReferenciaMB}
                      </p>
                      <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleData2 as CSSProperties}>
                        <b><FormattedMessage id="myihtp.encomendas.DadosPagamento.mb.Valor" />:</b>{" "}
                        <Label
                          text={props.details.PrecoFinal?.toFixed(2)}
                          labelType={{
                            type: Type.currency,
                            currency: selectedCurrency
                          }} />
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : null}
            <Row>
              <Col xs="12" sm="12" md="6" lg="6">
                <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleSubtitle as CSSProperties}>
                  {
                    Configs.myIHTP.tableConfig.orderDetails.data.idEncomenda.title
                  }
                </p>
                <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleData as CSSProperties}>
                  {props.details.ID_Encomenda}
                </p>
              </Col>
              <Col xs="12" sm="12" md="6" lg="6">
                <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleSubtitle as CSSProperties}>
                  {
                    Configs.myIHTP.tableConfig.orderDetails.data.dataEncomenda
                      .title
                  }
                </p>
                <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleData as CSSProperties}>
                  <FormattedDate value={new Date(props.details.DataEncomenda)} />
                </p>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs="12" sm="12" md="6" lg="6">
                <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleSubtitle as CSSProperties}>
                  {
                    Configs.myIHTP.tableConfig.orderDetails.data
                      .estadoEncomenda.title
                  }
                </p>
                <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleData as CSSProperties}>
                  {props.details.EstadoEncomenda}
                </p>
              </Col>
              <Col xs="12" sm="12" md="6" lg="6">
                <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleSubtitle as CSSProperties}>
                  {
                    Configs.myIHTP.tableConfig.orderDetails.data
                      .tipoTransporte.title
                  }
                </p>
                <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleData as CSSProperties}>
                  {props.details.DescricaoTipoTransporte}
                </p>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs="12" sm="12" md="6" lg="6">
                <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleSubtitle as CSSProperties}>
                  {
                    Configs.myIHTP.tableConfig.orderDetails.data.tipoPagamento
                      .title
                  }
                </p>
                <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleData as CSSProperties}>
                  {props.details.DescricaoTipoPagamento}
                </p>
              </Col>
              <Col xs="12" sm="12" md="3" lg="3">
                <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleSubtitle as CSSProperties}>
                  {
                    Configs.myIHTP.tableConfig.orderDetails.data
                      .dadosPagamento.title
                  }
                </p>
                <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleData as CSSProperties}>
                  <FormattedDate value={new Date(props.details.DataPagamento)} />
                </p>
              </Col>
              <Col xs="12" sm="12" md="3" lg="3">
                <p style={Configs.myIHTP.tableConfig.encomendas.orderDetails.styleSubtitle as CSSProperties}                >
                  {
                    Configs.myIHTP.tableConfig.orderDetails.data.dataEnvio
                      .title
                  }
                </p>
                <p
                  style={
                    Configs.myIHTP.tableConfig.encomendas.orderDetails
                      .styleData as CSSProperties
                  }
                >
                  <FormattedDate value={new Date(props.details.DataEnvio)} />
                </p>
              </Col>
            </Row>
            <br />
            <Row>
              {/* Dados de Envio */}
              <Col xs="12" sm="12" md="6" lg="6">
                <p
                  style={
                    Configs.myIHTP.tableConfig.encomendas.orderDetails
                      .styleSubtitle as CSSProperties
                  }
                >
                  {
                    Configs.myIHTP.tableConfig.orderDetails.data.dadosEnvio
                      .title
                  }
                </p>
                <p
                  style={
                    Configs.myIHTP.tableConfig.encomendas.orderDetails
                      .styleData as CSSProperties
                  }
                >
                  {props.details.moradaEnvioMorada ? (
                    <div>
                      <p>{props.details.moradaEnvioNome}</p>
                      <p>{props.details.moradaEnvioMorada}</p>
                      <p>
                        {props.details.moradaEnvioCodigoPostal}{" "}
                        {props.details.moradaEnvioLocalidade}
                      </p>
                      <p>{props.details.moradaEnvioPais}</p>
                    </div>
                  ) : (
                    <p><FormattedMessage id="myihtp.encomendas.SemMoradaEnvio" /></p>
                  )}
                </p>
              </Col>
              {/* Dados de Faturacao */}
              <Col xs="12" sm="12" md="6" lg="6">
                <p
                  style={
                    Configs.myIHTP.tableConfig.encomendas.orderDetails
                      .styleSubtitle as CSSProperties
                  }
                >
                  {
                    Configs.myIHTP.tableConfig.orderDetails.data
                      .dadosFaturacao.title
                  }
                </p>
                <p
                  style={
                    Configs.myIHTP.tableConfig.encomendas.orderDetails
                      .styleData as CSSProperties
                  }
                >
                  {props.details.moradaFaturacaoMorada ? (
                    <div>
                      <p>{props.details.moradaFaturacaoNome}</p>
                      <p>{props.details.moradaFaturacaoNif}</p>
                      <p>{props.details.moradaFaturacaoMorada}</p>
                      <p>
                        {props.details.moradaFaturacaoCodigoPostal}{" "}
                        {props.details.moradaFaturacaoLocalidade}
                      </p>
                      <p>{props.details.moradaFaturacaoPais}</p>
                    </div>
                  ) : (
                    <p><FormattedMessage id="myihtp.encomendas.SemMoradaFaturacao" /></p>
                  )}
                </p>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs="12" sm="12" md="6" lg="6">
                <p
                  style={
                    Configs.myIHTP.tableConfig.encomendas.orderDetails
                      .styleSubtitle as CSSProperties
                  }
                >
                  {
                    Configs.myIHTP.tableConfig.orderDetails.data.precoTotal
                      .title
                  }
                </p>
                <p
                  style={
                    Configs.myIHTP.tableConfig.encomendas.orderDetails
                      .styleData as CSSProperties
                  }
                >
                  <Label
                    text={props.details.PrecoTotal?.toFixed(2)}
                    labelType={{
                      type: Type.currency,
                      currency: selectedCurrency
                    }} />
                </p>
              </Col>
              <Col xs="12" sm="12" md="6" lg="6">
                <p
                  style={
                    Configs.myIHTP.tableConfig.encomendas.orderDetails
                      .styleSubtitle as CSSProperties
                  }
                >
                  {
                    Configs.myIHTP.tableConfig.orderDetails.data.descontoTotal
                      .title
                  }
                </p>
                <p
                  style={
                    Configs.myIHTP.tableConfig.encomendas.orderDetails
                      .styleData as CSSProperties
                  }
                >
                  <Label
                    text={props.details.DescontoTotal?.toFixed(2)}
                    labelType={{
                      type: Type.currency,
                      currency: selectedCurrency
                    }} />
                </p>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs="12" sm="12" md="6" lg="6">
                <p
                  style={
                    Configs.myIHTP.tableConfig.encomendas.orderDetails
                      .styleSubtitle as CSSProperties
                  }
                >
                  {
                    Configs.myIHTP.tableConfig.orderDetails.data.ivaTotal
                      .title
                  }
                </p>
                <p
                  style={
                    Configs.myIHTP.tableConfig.encomendas.orderDetails
                      .styleData as CSSProperties
                  }
                >
                  <Label
                    text={props.details.IvaTotal?.toFixed(2)}
                    labelType={{
                      type: Type.currency,
                      currency: selectedCurrency
                    }} />
                </p>
              </Col>
              <Col xs="12" sm="12" md="6" lg="6">
                <p
                  style={
                    Configs.myIHTP.tableConfig.encomendas.orderDetails
                      .styleSubtitle as CSSProperties
                  }
                >
                  {
                    Configs.myIHTP.tableConfig.orderDetails.data
                      .custoTransporte.title
                  }
                </p>
                <p
                  style={
                    Configs.myIHTP.tableConfig.encomendas.orderDetails
                      .styleData as CSSProperties
                  }
                >
                  <Label
                    text={props.details.CustoTransporte?.toFixed(2)}
                    labelType={{
                      type: Type.currency,
                      currency: selectedCurrency
                    }} />
                </p>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs="12" sm="12" md="6" lg="6">
                <p
                  style={
                    Configs.myIHTP.tableConfig.encomendas.orderDetails
                      .styleSubtitle as CSSProperties
                  }
                >
                  {
                    Configs.myIHTP.tableConfig.orderDetails.data
                      .descontoCredito.title
                  }
                </p>
                <p
                  style={
                    Configs.myIHTP.tableConfig.encomendas.orderDetails
                      .styleData as CSSProperties
                  }
                >
                  <Label
                    text={props.details.DescontoCredito?.toFixed(2)}
                    labelType={{
                      type: Type.currency,
                      currency: selectedCurrency
                    }} />
                </p>
              </Col>
              <Col xs="12" sm="12" md="6" lg="6">
                <p
                  style={
                    Configs.myIHTP.tableConfig.encomendas.orderDetails
                      .styleSubtitle as CSSProperties
                  }
                >
                  {
                    Configs.myIHTP.tableConfig.orderDetails.data.precoFinal
                      .title
                  }
                </p>
                <p
                  style={
                    Configs.myIHTP.tableConfig.encomendas.orderDetails
                      .styleData as CSSProperties
                  }
                >
                  <Label
                    text={props.details.PrecoFinal?.toFixed(2)}
                    labelType={{
                      type: Type.currency,
                      currency: selectedCurrency
                    }} />
                </p>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs="12" sm="12" md="12" lg="12">
                <p
                  style={
                    Configs.myIHTP.tableConfig.encomendas.orderDetails
                      .styleSubtitle as CSSProperties
                  }
                >
                  {
                    Configs.myIHTP.tableConfig.orderDetails.data.observacao
                      .title
                  }
                </p>
                <p
                  style={
                    Configs.myIHTP.tableConfig.encomendas.orderDetails
                      .styleData as CSSProperties
                  }
                >
                  {props.details.Observacao
                    ? props.details.Observacao
                    : <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.evolucaoEncomenda.NaoHaObservacoes" />}
                </p>
              </Col>
            </Row>
            <br />
            <p
              style={
                Configs.myIHTP.tableConfig.encomendas.itensDetails.styleTitle as CSSProperties
              }
            >
              {Configs.myIHTP.tableConfig.itensDetails.title}
            </p>
            <ReactTable
              getTheadProps={(state, rowInfo, column) => {
                return {
                  style:
                    Configs.myIHTP.tableConfig.encomendas.itensDetails
                      .styleTable,
                };
              }}
              getPaginationProps={state => {
                return {
                  style: {
                    color: Configs.myIHTP.tableConfig.colorHeader,
                    backgroundColor:
                      Configs.myIHTP.tableConfig.backgroundColorHeader,
                    fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody,
                  },
                };
              }}
              data={props.itens}
              columns={[
                {
                  Header:
                    Configs.myIHTP.tableConfig.itensDetails.columns.nome
                      .header,
                  accessor:
                    Configs.myIHTP.tableConfig.itensDetails.columns.nome
                      .accessor,
                  style: {
                    "text-align": "center",
                  },
                },
                {
                  Header:
                    Configs.myIHTP.tableConfig.itensDetails.columns.iva
                      .header,
                  accessor:
                    Configs.myIHTP.tableConfig.itensDetails.columns.iva
                      .accessor,
                  width: 100,
                  style: {
                    "text-align": "center",
                  },
                  Cell: row => {
                    return (
                      <FormattedNumber
                        value={row.original.TaxaIva}
                        style="percent"
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                      />
                    );
                  },
                },
                {
                  Header:
                    Configs.myIHTP.tableConfig.itensDetails.columns.quantidade
                      .header,
                  accessor:
                    Configs.myIHTP.tableConfig.itensDetails.columns.quantidade
                      .accessor,
                  width: 100,
                  style: {
                    "text-align": "center",
                  },
                },
                {
                  Header:
                    Configs.myIHTP.tableConfig.itensDetails.columns
                      .precoUnitario.header,
                  accessor:
                    Configs.myIHTP.tableConfig.itensDetails.columns
                      .precoUnitario.accessor,
                  //   user.userType === "CS"
                  //     ? Configs.myIHTP.tableConfig.itensDetails.columns
                  //         .precoUnitarioCS.accessor
                  //     : Configs.myIHTP.tableConfig.itensDetails.columns
                  //         .precoUnitario.accessor,
                  width: 100,
                  style: {
                    "text-align": "center",
                  },
                  Cell: row => {
                    return (
                      <Label
                        text={row.original.PrecoUnitarioComIva?.toFixed(2)}
                        labelType={{
                          type: Type.currency,
                          currency: selectedCurrency
                        }} />
                    );
                  },
                },
              ]}
              showPagination={Configs.myIHTP.tableConfig.showPagination}
              defaultPageSize={Configs.myIHTP.tableConfig.defaultPageSize}
            />
            <br />
            <p
              style={
                Configs.myIHTP.tableConfig.encomendas.changeOrderState
                  .styleTitle as CSSProperties
              }
            >
              {Configs.myIHTP.tableConfig.changeOrderState.title}
            </p>
            {props.estados ? (
              <ReactTable
                getTheadProps={(state, rowInfo, column) => {
                  return {
                    style:
                      Configs.myIHTP.tableConfig.encomendas.changeOrderState
                        .styleTable,
                  };
                }}
                getPaginationProps={state => {
                  return {
                    style: {
                      color: Configs.myIHTP.tableConfig.colorHeader,
                      backgroundColor:
                        Configs.myIHTP.tableConfig.backgroundColorHeader,
                      fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody,
                    },
                  };
                }}
                data={props.estados}
                columns={[
                  {
                    Header:
                      Configs.myIHTP.tableConfig.changeOrderState.columns.data
                        .header,
                    accessor:
                      Configs.myIHTP.tableConfig.changeOrderState.columns.data
                        .accessor,
                    style: {
                      "text-align": "center",
                    },
                    Cell: row => {
                      return (
                        <FormattedDate
                          value={new Date(row.original.DataMudancaEstado)}
                        />
                      );
                    },
                  },
                  {
                    Header:
                      Configs.myIHTP.tableConfig.changeOrderState.columns
                        .estadoEncomenda.header,
                    accessor:
                      Configs.myIHTP.tableConfig.changeOrderState.columns
                        .estadoEncomenda.accessor,
                    style: {
                      "text-align": "center",
                    },
                  },
                  {
                    Header:
                      Configs.myIHTP.tableConfig.changeOrderState.columns
                        .observacao.header,
                    accessor:
                      Configs.myIHTP.tableConfig.changeOrderState.columns
                        .observacao.accessor,
                    style: {
                      "text-align": "center",
                    },
                    Cell: row => {
                      return row.original.Observacao
                        ? row.original.Observacao
                        : <FormattedMessage id="myihtp.ultimasEncomendasElementosEstrutura.evolucaoEncomenda.NaoHaObservacoes" />
                    },
                  },
                  {
                    Header:
                      Configs.myIHTP.tableConfig.changeOrderState.columns
                        .emailEnviado.header,
                    accessor:
                      Configs.myIHTP.tableConfig.changeOrderState.columns
                        .emailEnviado.accessor,
                    style: {
                      "text-align": "center",
                    },
                    Cell: row => {
                      return (
                        <span>
                          {row.original.EnviadoMail.startsWith("S") ? (
                            <FormattedMessage id="myihtp.infopessoal.moradas.sim" />
                          ) : (
                            <FormattedMessage id="myihtp.infopessoal.moradas.nao" />
                          )}
                        </span>
                      )
                    }
                  },
                ]}
                showPagination={Configs.myIHTP.tableConfig.showPagination}
                defaultPageSize={Configs.myIHTP.tableConfig.defaultPageSize}
              />
            ) : null}
          </div>
          <br />
        </Container>
      </Dialog>
    </div>
  );
}