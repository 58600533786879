import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import "react-table/react-table.css";
import { getResumeComissoes } from "../../../../../../actions/myihtpActions";
import useCurrencies from "../../../../../../hooks/useCurrencies";
import Label, { Type } from "../../../../../Common/Label";

export default function Resumo() {
  const [ganhas, setGanhas] = useState<number>(0);
  const [pagas, setPagas] = useState<number>(0);
  const [receber, setReceber] = useState<number>(0);
  const [podeReceber, setPodeReceber] = useState<number>(0);

  const currencies = useCurrencies();

  useEffect(() => {
    getResumeComissoes()
      .then(data => {
        if (!data.comissoes.error) {
          setGanhas(data.comissoes.ComissionsResume[0].ValorTotal ?? 0);
          setPagas(data.comissoes.ComissionsResume[0].ValorPago ?? 0);
          setReceber(data.comissoes.ComissionsResume[0].Receber ?? 0);
          setPodeReceber(data.comissoes.ComissionsResume[0].PodeReceber ?? 0);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }, [])

  return (
    <Row>
      <Col xs={12} sm={12} md={12} lg={12}>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <FormattedMessage id="myihtp.comissoes.resumo.title" />{" "}
            <FormattedMessage id="myihtp.comissoes.resumo.detail.title" />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Label
              text={ganhas.toFixed(2)}
              labelType={{
                type: Type.currency,
                currency: currencies.selectedDescription
              }} />
            {" "}
            {" - "}{" "}
            <Label
              text={pagas.toFixed(2)}
              labelType={{
                type: Type.currency,
                currency: currencies.selectedDescription
              }} />
            {" = "}
            <Label
              text={receber.toFixed(2)}
              labelType={{
                type: Type.currency,
                currency: currencies.selectedDescription
              }} />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <FormattedMessage id="myihtp.comissoes.resumo.valorDisponivel.title" />{" "}
            <Label
              text={podeReceber.toFixed(2)}
              labelType={{
                type: Type.currency,
                currency: currencies.selectedDescription
              }} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}