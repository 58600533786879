import { Col, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { FormattedMessage } from "react-intl";
import Label, { Type } from "../Common/Label";
import "./Payment.css";

export interface PaymentMBProps {
  total: number,
  entidade: string,
  referencia: string,
  currency: string
}

export default function PaymentMB({ total, entidade, referencia, currency }: PaymentMBProps) {
  return (
    <div>
      <Row className="payment">
        <Col
          xs={12} sm={12} md={6} lg={6}
          style={{
            textAlign: isMobile ? "center" : "right",
            margin: "auto",
            top: "0px",
            bottom: "0px"
          }}
        >
          <img src="https://my.ihavethepower.net/Content/imagens/emails/multibanco.png" className="logoPayment" alt="" style={{ width: "34%" }}
          />
        </Col>
        <Col xs={12} sm={12} md={6} lg={6}
          style={{
            textAlign: isMobile ? "center" : "left",
            margin: "auto",
            top: "0px",
            bottom: "0px"
          }}
        >
          <p><b><FormattedMessage id="pagamento.alterarTipoPagamento.mb.entidade" /></b> {entidade}</p>
          <p><b><FormattedMessage id="pagamento.alterarTipoPagamento.mb.referencia" /></b> {referencia}</p>
          <p>
            <b><FormattedMessage id="pagamento.alterarTipoPagamento.mb.valor" /> </b>
            <Label
              text={total.toFixed(2)}
              labelType={{
                type: Type.currency,
                currency: currency
              }} />
          </p>
        </Col>
      </Row>
    </div>
  );
}