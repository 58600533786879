import { useEffect, useMemo, useState } from "react";

import { FormattedHTMLMessage, FormattedMessage, useIntl } from "react-intl";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { getComparativeMonthlyDashboard } from "../../../../../../../actions/myihtpActions";
import Configs from "../../../../../../../helpers/Configs";
import useCurrencies from "../../../../../../../hooks/useCurrencies";
import Label, { Type } from "../../../../../../Common/Label";
import ArrowStatus from "../ArrowStatus";

export default function ComparativoMensalDashboard() {
  const [comparativeMonthly, setComparativeMonthly] = useState<[]>([]);
  const currencies = useCurrencies();
  const intl = useIntl();

  useEffect(() => {
    getComparativeMonthlyDashboard()
      .then(data => {
        if (
          data.ComparativeMonthly.message !== "Não tem dados disponiveis." &&
          !data.ComparativeMonthly.error
        ) {
          setComparativeMonthly(data.ComparativeMonthly)
        }
      })
      .catch(() => {
        setComparativeMonthly([]);
      });
  }, [])

  const columns = useMemo(() => {
    const columns = [
      {
        Header: (
          <FormattedMessage id="myihtp.comparativoMensal.table.AnoMes" />
        ),
        accessor: "Ano",
        resizable: false,
        Cell: row => (
          <div>
            <span title={row.value}>{row.value}</span>
          </div>
        )
      },
      {
        Header: (
          <FormattedHTMLMessage id="myihtp.comparativoMensal.table.NPCAlias" />
        ),
        accessor: "NPC",
        resizable: false,
        Cell: row => {
          return (
            <div>
              {row.index === 0 ? (
                <ArrowStatus status={row.original.ArrowStatusNPC} />
              ) : null}
              &nbsp;&nbsp;
              {row.original.NPC}
            </div>
          );
        }
      },
      {
        Header: (
          <FormattedHTMLMessage id="myihtp.comparativoMensal.table.NPPAlias" />
        ),
        accessor: "NPP",
        resizable: false,
        Cell: row => {
          return (
            <div>
              {row.index === 0 ? (
                <ArrowStatus status={row.original.ArrowStatusNPP} />
              ) : null}
              &nbsp;&nbsp;
              {row.original.NPP}
            </div>
          );
        }
      },
      {
        Header: (
          <FormattedHTMLMessage id="myihtp.comparativoMensal.table.NPRAlias" />
        ),
        accessor: "NPR",
        resizable: false,
        Cell: row => {
          return (
            <div>
              {row.index === 0 ? (
                <ArrowStatus status={row.original.ArrowStatusNPR} />
              ) : null}
              &nbsp;&nbsp;
              {row.original.NPR}
            </div>
          );
        }
      },
      {
        Header: (
          <FormattedHTMLMessage id="myihtp.comparativoMensal.table.CRDAlias" />
        ),
        accessor: "CRD",
        resizable: false,
        Cell: row => {
          return (
            <div>
              {row.index === 0 ? (
                <ArrowStatus status={row.original.ArrowStatusCRD} />
              ) : null}
              &nbsp;&nbsp;
              {row.original.CRD}
            </div>
          );
        }
      },
      {
        Header: (
          <FormattedHTMLMessage id="myihtp.comparativoMensal.table.VCMAlias" />
        ),
        accessor: "VCM",
        resizable: false,
        width: 150,
        Cell: row => {
          return (
            <div>
              {row.index === 0 ? (
                <ArrowStatus status={row.original.ArrowStatusVCM} />
              ) : null}
              &nbsp;&nbsp;
              <Label
                text={row.original.VCM.toFixed(2)}
                labelType={{
                  type: Type.currency,
                  currency: currencies.selectedDescription
                }} />
            </div>
          );
        }
      },
      {
        Header: (
          <FormattedHTMLMessage id="myihtp.comparativoMensal.table.NENAlias" />
        ),
        accessor: "NEN",
        resizable: false,
        Cell: row => {
          return (
            <div>
              {row.index === 0 ? (
                <ArrowStatus status={row.original.ArrowStatusNEN} />
              ) : null}
              &nbsp;&nbsp;
              {row.original.NEN}
            </div>
          );
        }
      },
      {
        Header: (
          <FormattedHTMLMessage id="myihtp.comparativoMensal.table.VENAlias" />
        ),
        accessor: "VEN",
        resizable: false,
        width: 150,
        Cell: row => {
          return (
            <div>
              {row.index === 0 ? (
                <ArrowStatus status={row.original.ArrowStatusVEN} />
              ) : null}
              &nbsp;&nbsp;
              <Label
                text={row.original.VEN.toFixed(2)}
                labelType={{
                  type: Type.currency,
                  currency: currencies.selectedDescription
                }} />
            </div>
          );
        }
      },
      {
        Header: (
          <FormattedHTMLMessage id="myihtp.comparativoMensal.table.NPTAlias" />
        ),
        accessor: "NPT",
        resizable: false,
        Cell: row => {
          return (
            <div>
              {row.index === 0 ? (
                <ArrowStatus status={row.original.ArrowStatusNPT} />
              ) : null}
              &nbsp;&nbsp;
              {row.original.NPT}
            </div>
          );
        }
      },
      {
        Header: (
          <FormattedHTMLMessage id="myihtp.comparativoMensal.table.TBGAlias" />
        ),
        accessor: "TBG",
        resizable: false,
        Cell: row => {
          return (
            <div>
              {row.index === 0 ? (
                <ArrowStatus status={row.original.ArrowStatusTBG} />
              ) : null}
              &nbsp;&nbsp;
              {row.original.TBG}
            </div>
          );
        }
      },
      {
        Header: (
          <FormattedHTMLMessage id="myihtp.comparativoMensal.table.VBGAlias" />
        ),
        accessor: "VBG",
        resizable: false,
        Cell: row => {
          return (
            <div>
              {row.index === 0 ? (
                <ArrowStatus status={row.original.ArrowStatusVBG} />
              ) : null}
              &nbsp;&nbsp;
              {row.original.VBG}
            </div>
          );
        }
      },
      {
        Header: (
          <FormattedHTMLMessage id="myihtp.comparativoMensal.table.NRTAlias" />
        ),
        accessor: "NRT",
        resizable: false,
        Cell: row => {
          return (
            <div>
              {row.index === 0 ? (
                <ArrowStatus status={row.original.ArrowStatusNRT} />
              ) : null}
              &nbsp;&nbsp;
              {row.original.NRT}
            </div>
          );
        }
      },
      {
        Header: (
          <FormattedHTMLMessage id="myihtp.comparativoMensal.table.TBLAlias" />
        ),
        accessor: "TBL",
        resizable: false,
        Cell: row => {
          return (
            <div>
              {row.index === 0 ? (
                <ArrowStatus status={row.original.ArrowStatusTBL} />
              ) : null}
              &nbsp;&nbsp;
              {row.original.TBL}
            </div>
          );
        }
      },
      {
        Header: (
          <FormattedHTMLMessage id="myihtp.comparativoMensal.table.VBLDAlias" />
        ),
        accessor: "VBLD",
        resizable: false,
        Cell: row => {
          return (
            <div>
              {row.index === 0 ? (
                <ArrowStatus status={row.original.ArrowStatusVBLD} />
              ) : null}
              &nbsp;&nbsp;
              {row.original.VBLD}
            </div>
          );
        }
      },
      {
        Header: (
          <FormattedHTMLMessage id="myihtp.comparativoMensal.table.VBLAlias" />
        ),
        accessor: "VBL",
        resizable: false,
        Cell: row => {
          return (
            <div>
              {row.index === 0 ? (
                <ArrowStatus status={row.original.ArrowStatusVBL} />
              ) : null}
              &nbsp;&nbsp;
              {row.original.VBL}
            </div>
          );
        }
      },
      {
        Header: (
          <FormattedHTMLMessage id="myihtp.comparativoMensal.table.NLBAlias" />
        ),
        accessor: "NLB",
        resizable: false,
        Cell: row => {
          return (
            <div>
              {row.index === 0 ? (
                <ArrowStatus status={row.original.ArrowStatusNLB} />
              ) : null}
              &nbsp;&nbsp;
              {row.original.NLB}
            </div>
          );
        }
      },
      {
        Header: (
          <FormattedHTMLMessage id="myihtp.comparativoMensal.table.VLBAlias" />
        ),
        accessor: "VLB",
        resizable: false,
        Cell: row => {
          return (
            <div>
              {row.index === 0 ? (
                <ArrowStatus status={row.original.ArrowStatusVLB} />
              ) : null}
              &nbsp;&nbsp;
              {row.original.VLB}
            </div>
          );
        }
      },
      {
        Header: (
          <FormattedHTMLMessage id="myihtp.comparativoMensal.table.VTLAlias" />
        ),
        accessor: "VTL",
        resizable: false,
        width: 150,
        Cell: row => {
          return (
            <div>
              {row.index === 0 ? (
                <ArrowStatus status={row.original.ArrowStatusVTL} />
              ) : null}
              &nbsp;&nbsp;
              <Label
                text={row.original.VTL.toFixed(2)}
                labelType={{
                  type: Type.currency,
                  currency: currencies.selectedDescription
                }} />
            </div>
          );
        }
      }
    ]

    return columns;
  }, []);

  return (
    <div>
      <ReactTable
        className="-highlight tableReact"
        data={comparativeMonthly}
        getTheadProps={(state, rowInfo, column) => {
          return {
            style: {
              fontWeight: Configs.myIHTP.tableConfig.fontHeightHeader,
              fontSize: Configs.myIHTP.tableConfig.fontSizeHeader,
              height: Configs.myIHTP.tableConfig.heightHeader,
              color: Configs.myIHTP.tableConfig.colorHeader,
              backgroundColor:
                Configs.myIHTP.tableConfig.backgroundColorHeader
            }
          };
        }}
        getPaginationProps={state => {
          return {
            style: {
              color: Configs.myIHTP.tableConfig.colorHeader,
              backgroundColor:
                Configs.myIHTP.tableConfig.backgroundColorHeader,
              fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody
            }
          };
        }}
        getTrProps={(state, rowInfo, column, instance) => {
          return {
            style: {
              "text-align": "center",
              "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody
            }
          };
        }}
        defaultPageSize={2}
        showPagination={false}
        previousText={Configs.myIHTP.tableConfig.buttonCopyAnterior}
        nextText={Configs.myIHTP.tableConfig.buttonCopyProximo}
        noDataText={Configs.myIHTP.tableConfig.noDataText}
        pageText={Configs.myIHTP.tableConfig.pageText}
        ofText={Configs.myIHTP.tableConfig.ofText}
        rowsText={intl.formatMessage({ id: "table.config.rowsText" })}
        columns={columns}
      />
    </div >
  );
}