import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { getVales, sendVoucher, validateCodeGuest } from "../../../../../../actions/myihtpActions";
import Configs from "../../../../../../helpers/Configs";
import Toast from "../../../../../../helpers/Toast";
import useCurrencies from "../../../../../../hooks/useCurrencies";
import Label, { Type } from "../../../../../Common/Label";
import { activateCode } from "../Helper";
import DialogOferecerVale from "./DialogOferecerVale";

interface NovoVale {
  codigo: string,
  email: string
}

export default function ListagemVales() {
  const [open, setOpen] = useState<boolean>(false);
  const [idVale, setIdVale] = useState<number | null>(null);
  const [total, setTotal] = useState<number>(0);
  const [gasto, setGasto] = useState<number>(0);
  const [disponivel, setDisponivel] = useState<number>(0);
  const [novoVale, setNovoVale] = useState<NovoVale>({} as NovoVale)
  const [vouchers, setVouchers] = useState<[]>([]);
  const [selectedValue, setSelectedValue] = useState<string>();

  const currencies = useCurrencies();
  const intl = useIntl();

  useEffect(() => {
    getVales().then(data => {
      if (data.success === true) {
        setTotal(data.obj.Total ?? 0);
        setGasto(data.obj.Gasto ?? 0);
        setDisponivel(data.obj.Disponivel ?? 0);
        setVouchers(data.obj.Vales)
      }
    });
  }, [])

  const validateCode = async (e) => {
    e.stopPropagation();
    validateCodeGuest(novoVale.codigo)
      .then(data => {
        if (data.success === true) {
          getVales().then(data => {
            if (data.success === true) {
              setVouchers(data.obj.Vales)
            }

            Toast.Show("success", intl.formatMessage({ id: "myihtp.vales.sucessoAtivacaoVale" }))
          });
        } else {
          Toast.Show("error", data.message);
        }
      })
      .catch(error => {
        Toast.Show("error", intl.formatMessage({ id: "myihtp.vales.erroAtivacaoVale" }))
      });
  };

  const validadeCodigoVale = (email: string) => {
    sendVoucher(idVale, email)
      .then(data => {
        if (data.success === true) {
          setOpen(false);

          getVales().then(data => {
            if (data.success === true) {
              setVouchers(data.obj.Vales);
            }

            Toast.Show("success", intl.formatMessage({ id: "myihtp.vales.valeEnviado" }))
          });
        } else {
          Toast.Show("error", data.message);
        }
      })
      .catch(err => {
        Toast.Show("error", intl.formatMessage({ id: "myihtp.vales.erroAtivacaoVale" }));
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = value => {
    setOpen(false);
    setSelectedValue(value);
  };


  const vales = useMemo(() => {
    const columns = [
      {
        Header: <FormattedMessage id="myihtp.vales.ID_Vale" />,
        accessor: "ID_Vale",
        resizable: false
      },
      {
        Header: <FormattedMessage id="myihtp.vales.Valor" />,
        accessor: "Valor",
        resizable: false,
        Cell: row => {
          return (
            <Label
              text={row.original.Valor}
              labelType={{
                type: Type.currency,
                currency: currencies.selectedDescription
              }} />
          );
        }
      },
      {
        Header: <FormattedMessage id="myihtp.vales.Nome" />,
        accessor: "ClienteValeActivo",
        resizable: false
      },
      {
        Header: <FormattedMessage id="myihtp.vales.Utilizado" />,
        accessor: "Utilizado",
        resizable: false
      },
      {
        Header: <FormattedMessage id="myihtp.vales.ValorRemanescente" />,
        accessor: "ValorRemanescente",
        resizable: false,
        Cell: row => {
          return (
            <Label
              text={row.original.ValorRemanescente}
              labelType={{
                type: Type.currency,
                currency: currencies.selectedDescription
              }} />
          );
        }
      },
      {
        Header: "",
        accessor: "ativar",
        resizable: false,
        width: 50,
        Cell: row => {
          return (
            <div>
              {!row.original.ClienteValeActivo ? (
                <a>
                  <svg
                    height="25px"
                    viewBox="0 0 512 512.00115"
                    width="25px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0,437.825v-345.6c0-13.5,10.9-24.4,24.4-24.4h255.1h51.3l-48.7,48.7h-2.6H48.7v296.9h296.9v-53.3v-72.8l48.7-48.7v121.5
  v77.7c0,13.4-10.9,24.4-24.4,24.4H24.4C10.9,462.225,0,451.325,0,437.825z M212.1,250.825l-52-52c-14.6-14.6-38.3-14.6-52.9,0
  c-7.1,7.1-11,16.5-11,26.5s3.9,19.4,11,26.4l78.4,78.4c14.6,14.6,38.3,14.6,52.9,0L478,90.625c7.1-7.1,11-16.5,11-26.4
  c0-10-3.9-19.4-11-26.5c-14.6-14.6-38.3-14.6-52.9,0L212.1,250.825z"
                    />
                  </svg>
                </a>
              ) : null}
            </div>
          );
        }
      },
      {
        Header: "",
        accessor: "enviar",
        resizable: false,
        width: 50,
        Cell: row => {
          return (
            <div>
              {!row.original.ClienteValeActivo ? (
                <a>
                  <svg
                    height="25px"
                    viewBox="0 0 512 512.00115"
                    width="25px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M97.018,341.333H10.78C4.821,341.333,0,346.104,0,352c0,5.896,4.821,10.667,10.78,10.667h86.238
    c5.958,0,10.78-4.771,10.78-10.667C107.798,346.104,102.977,341.333,97.018,341.333z"
                    />
                    <path
                      d="M107.798,277.333H10.78C4.821,277.333,0,282.104,0,288s4.821,10.667,10.78,10.667h97.018
    c5.958,0,10.78-4.771,10.78-10.667S113.756,277.333,107.798,277.333z"
                    />
                    <path
                      d="M118.578,213.333H10.78C4.821,213.333,0,218.104,0,224s4.821,10.667,10.78,10.667h107.798
    c5.958,0,10.78-4.771,10.78-10.667S124.536,213.333,118.578,213.333z"
                    />
                    <path
                      d="M201.448,172.208c42.888,41.656,91.512,88.885,106.04,102.417c2.011,1.865,4.727,2.833,7.464,2.833
    c1.284,0,2.569-0.208,3.79-0.646c0.937-0.333,3.442-1.219,6.39-3.219c18.938-12.844,80.754-53.323,138.548-91.167l40.319-26.406
    c2.695-1.771,4.453-4.656,4.769-7.844c0.316-3.188-0.821-6.344-3.116-8.615C498.081,132.104,488.039,128,477.364,128H209.501
    c-9.611,0-19.096,3.292-26.728,9.26c-2.421,1.906-3.906,4.74-4.074,7.802c-0.158,3.063,1.011,6.042,3.221,8.177L201.448,172.208z
    "
                    />
                    <path
                      d="M507.282,187.406c-3.621-2.427-8.359-2.469-12.001-0.083l-24.097,15.781c-56.383,36.917-115.441,75.594-133.874,88.083
    c-14.885,10.135-33.171,9.625-44.604-1.042c-14.685-13.667-63.394-60.979-106.935-103.271l-5.664-5.5
    c-2.811-2.74-6.885-3.74-10.674-2.604c-3.769,1.125-6.622,4.198-7.432,8.01l-31.781,149.385
    c-0.568,2.688-0.863,5.438-0.863,8.188c0,21.865,17.97,39.646,40.066,39.646h267.863c20.265,0,38.013-14.24,42.203-33.875
    l32.276-151.698C512.672,194.198,510.893,189.844,507.282,187.406z"
                    />
                    <path
                      d="M10.78,170.667h118.578c5.958,0,10.78-4.771,10.78-10.667s-4.821-10.667-10.78-10.667H10.78
    C4.821,149.333,0,154.104,0,160S4.821,170.667,10.78,170.667z"
                    />
                  </svg>
                </a>
              ) : null}
            </div>
          );
        }
      }
    ]

    return columns
  }, []);

  return (
    <div>
      <DialogOferecerVale
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        validateFunction={validadeCodigoVale}
      />

      <Container>
        <Row>
          <Col xs={12} sm={12} md={5} lg={5}>
            <Row>
              <Col style={{ textAlign: "left" }}>
                <FormattedMessage id="myihtp.vales.resumoVales.title" />
                <br />
                <FormattedMessage id="myihtp.vales.resumoVales.title2" />
              </Col>
            </Row>
            <Row>
              <Col style={{ textAlign: "left" }}>
                <Label
                  text={total.toFixed(2)}
                  labelType={{
                    type: Type.currency,
                    currency: currencies.selectedDescription
                  }} />{" "}
                -{" "}
                <Label
                  text={gasto.toFixed(2)}
                  labelType={{
                    type: Type.currency,
                    currency: currencies.selectedDescription
                  }} />
                {" "}
                ={" "}
                <Label
                  text={disponivel.toFixed(2)}
                  labelType={{
                    type: Type.currency,
                    currency: currencies.selectedDescription
                  }} />
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={12} md={7} lg={7}>
            <Row>
              <Col
                xs={6}
                sm={6}
                md={6}
                lg={6}
                style={{
                  textAlign: "center",
                  alignItems: "center",
                  display: "flex"
                }}
              >
                <TextField
                  placeholder={intl.formatMessage({
                    id: "myihtp.vales.codigo"
                  })}
                  name={"codigo"}
                  fullWidth={true}
                  onChange={(a: any) => setNovoVale({ ...novoVale, codigo: a.target.value })}
                />
              </Col>
              <Col
                xs={6}
                sm={6}
                md={6}
                lg={6}
                style={{
                  textAlign: "center",
                  alignItems: "center",
                  display: "flex"
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={validateCode}
                  style={{ minWidth: "100%" }}
                >
                  <FormattedMessage id="myihtp.vales.butao" />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <br />
      <ReactTable
        className="-highlight tableReact"
        data={vouchers}
        getTheadProps={(state, rowInfo, column) => {
          return {
            style: {
              fontWeight: Configs.myIHTP.tableConfig.fontHeightHeader,
              fontSize: Configs.myIHTP.tableConfig.fontSizeHeader,
              height: Configs.myIHTP.tableConfig.heightHeader,
              color: Configs.myIHTP.tableConfig.colorHeader,
              backgroundColor:
                Configs.myIHTP.tableConfig.backgroundColorHeader
            }
          };
        }}
        getPaginationProps={state => {
          return {
            style: {
              color: Configs.myIHTP.tableConfig.colorHeader,
              backgroundColor:
                Configs.myIHTP.tableConfig.backgroundColorHeader,
              fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody
            }
          };
        }}
        getTdProps={(state, rowInfo, column, instance) => {
          return {
            onClick: (e, handleOriginal) => {
              if (column.id === "ativar") {
                var confirm = window.confirm(
                  intl.formatMessage({
                    id: "myihtp.vales.ativar"
                  })
                );
                if (confirm) {
                  activateCode(rowInfo.original.ID_Vale).then(data => {
                    if (data.success === true) {
                      getVales().then(data => {
                        if (data.success === true) {
                          setVouchers(data.obj.Vales);
                          Toast.Show("success", intl.formatMessage({ id: "myihtp.vales.sucessoAtivacaoVale" }))
                        }
                      }).catch(err => {
                        console.log(err);
                      });
                    } else {
                      Toast.Show("error", data.message);
                    }
                  });
                }
              } else {
                if (column.id === "enviar") {
                  setIdVale(rowInfo.original.ID_Vale)
                  handleClickOpen();
                } else {
                  if (handleOriginal) {
                    handleOriginal();
                  }
                }
              }
            },
            style: {
              "text-align": "center",
              "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody
            }
          };
        }}
        defaultPageSize={Configs.myIHTP.tableConfig.defaultPageSize}
        showPagination={
          vouchers
            ? vouchers.length >
              Configs.myIHTP.tableConfig.defaultPageSize
              ? Configs.myIHTP.tableConfig.showPagination
              : false
            : false
        }
        previousText={Configs.myIHTP.tableConfig.buttonCopyAnterior}
        nextText={Configs.myIHTP.tableConfig.buttonCopyProximo}
        noDataText={Configs.myIHTP.tableConfig.noDataText}
        pageText={Configs.myIHTP.tableConfig.pageText}
        ofText={Configs.myIHTP.tableConfig.ofText}
        rowsText={intl.formatMessage({ id: "table.config.rowsText" })}
        columns={vales}
      />
    </div>
  );
}