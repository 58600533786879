import { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { FormattedDate, useIntl } from "react-intl";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { getOrderDetails, getOrders } from "../../../../../actions/myihtpActions";
// import { refund } from "../../../../../Analytics/DataLayer";
import Configs from "../../../../../helpers/Configs";
import Toast from "../../../../../helpers/Toast";
import useCurrencies from "../../../../../hooks/useCurrencies";
import useUser from "../../../../../hooks/useUser";
import Label, { Type } from "../../../../Common/Label";
import OrderDetail, { OrderAddress, OrderState } from "../../../../Common/Order/OrderDetail";
import { OrderDetails, OrderProductsDetails } from "../Dashboard/UltimasEncomendas/UltimasEncomendas";
import CancelarEncomendaBotao from "./CancelarEncomendaBotao";
import "./Encomendas.css";
import FaturaBotao from "./FaturaBotao";
import { cancelOrder } from "./Helper";
import NotaCreditoBotao from "./NotaCreditoBotao";
import PagamentoBotao from "./PagamentoBotao";
import ReciboBotao from "./ReciboBotao";

export default function Encomendas() {
  const [orders, setOrders] = useState<[]>([]);
  const [details, setDetails] = useState<OrderDetails>({} as OrderDetails);
  const [itens, setItens] = useState<OrderProductsDetails[]>([]);
  const [estados, setEstados] = useState<[]>([]);
  const [expanded, setExpanded] = useState({});
  const [loadingDetails, setLoadingDetails] = useState<boolean>(false);

  const intl = useIntl();
  const user = useUser();
  const currencies = useCurrencies();

  useEffect(() => {
    getOrders().then(data => {
      if (!data.data.error) {
        setOrders(data.data.orders)
      }
    });
  }, [])

  const cancelOrderFunc = (id) => {
    var confirm = window.confirm(intl.formatMessage({ id: "myihtp.encomenda.cancelar" }));

    if (confirm) {
      cancelOrder(id).then(result => {

        //Se der sucesso ao cancelar a encomenda
        if (result.success === true) {

          //Cancela no GA4
          // refund(id);

          //Vamos buscar as encomendas atualizadas
          getOrders().then(data => {
            if (!data.data.error) {
              setOrders(data.data.orders)
            }
          });

          Toast.Show("success", result.message);
        } else {
          Toast.Show("error", result.message);
        }
      });
    }
  };

  const handleRowExpanded = (index) => {
    setExpanded({ [index[0]]: !expanded[index[0]] })
  }

  const getOrderDetailsData = (id, id_cliente) => async e => {
    setLoadingDetails(true);

    getOrderDetails(id, id_cliente)
      .then(data => {
        setLoadingDetails(false);

        if (data.success === true) {
          setDetails(data.obj.Detalhes[0]);
          setItens(data.obj.Itens);
          setEstados(data.obj.Estados);
        } else {
          Toast.Show("error", data.message);
        }
      })
      .catch(error => {
        console.log(error);
        setLoadingDetails(false);
      });
  };

  const getStates = (): OrderState[] => {
    if (estados == null || estados.length <= 0) return [];

    const result = estados.map((e: any) => {
      return {
        date: e.DataMudancaEstado,
        status: e.EstadoEncomenda,
        notes: e.Observacao,
        sentEmail: e.EnviadoMail
      }
    })

    return result;
  }

  const getShippingAddress = (): OrderAddress | undefined => {
    if (details.moradaEnvioMorada == null) return;

    return {
      address: details.moradaEnvioMorada,
      addressName: details.moradaEnvioNome,
      postalCode: details.moradaEnvioCodigoPostal,
      location: details.moradaEnvioLocalidade,
      country: details.moradaEnvioPais
    }
  }

  const columns = useMemo(() => {
    const columns = [
      {
        Header:
          Configs.myIHTP.tableConfig.encomendas.columns.idEncomenda.Header,
        accessor:
          Configs.myIHTP.tableConfig.encomendas.columns.idEncomenda.accessor
      },
      {
        Header:
          Configs.myIHTP.tableConfig.encomendas.columns.idEstadoEncomenda
            .Header,
        accessor:
          Configs.myIHTP.tableConfig.encomendas.columns.idEstadoEncomenda
            .accessor,
        show:
          Configs.myIHTP.tableConfig.encomendas.columns.idEstadoEncomenda.show
      },
      {
        Header:
          Configs.myIHTP.tableConfig.encomendas.columns.estadoEncomenda.Header,
        accessor:
          Configs.myIHTP.tableConfig.encomendas.columns.estadoEncomenda
            .accessor,
        show: !isMobile
      },
      {
        Header:
          Configs.myIHTP.tableConfig.encomendas.columns.dataEncomenda.Header,
        accessor:
          Configs.myIHTP.tableConfig.encomendas.columns.dataEncomenda.accessor,
        show: !isMobile,
        Cell: row => {
          return row.original.DataEncomenda ? (
            <FormattedDate value={new Date(row.original.DataEncomenda)} />
          ) : (
            "-"
          );
        }
      },
      {
        Header: Configs.myIHTP.tableConfig.encomendas.columns.precoFinal.Header,
        accessor:
          Configs.myIHTP.tableConfig.encomendas.columns.precoFinal.accessor,
        Cell: row => {
          const selectedCurrency = currencies.list?.find(a => a.ID_Moeda === row.original.ID_Moeda);

          return (
            <Label
              text={row.original.PrecoFinal.toFixed(2)}
              labelType={{
                type: Type.currency,
                currency: selectedCurrency?.Descricao ?? "€"
              }} />
          );
        }
      },
      {
        Header: "F",
        accessor: "fatura",
        width: 50,
        Cell: row => {
          if (row.original.UrlFatura) {
            return (
              <div>
                <FaturaBotao url={row.original.UrlFatura} />
              </div>
            );
          } else {
            return <div />;
          }
        },
        resizable: false
      },
      {
        Header: "R",
        accessor: "recibo",
        width: 50,
        Cell: row => {
          if (row.original.UrlRecibo) {
            return <ReciboBotao url={row.original.UrlRecibo} />;
          } else {
            return <div />;
          }
        },
        resizable: false
      },
      {
        Header: "NC",
        accessor: "notaCredito",
        width: 50,
        Cell: row => {
          if (row.original.UrlNotaCredito) {
            return <NotaCreditoBotao url={row.original.UrlNotaCredito} />;
          } else {
            return <div />;
          }
        },
        resizable: false
      },
      {
        Header: "P",
        accessor: "pagamento",
        width: 50,
        Cell: row => {
          if (
            !row.original.UrlFatura &&
            !row.original.UrlRecibo &&
            !row.original.NotaCredito
          ) {
            return <PagamentoBotao hash={row.original.HashEncomenda} />;
          } else {
            return <div />;
          }
        },
        resizable: false
      },
      {
        Header: "C",
        accessor: "cancelarEncomenda",
        width: 50,
        Cell: row => {
          if (row.original.ID_EstadoEncomenda === "AP") {
            return (
              <CancelarEncomendaBotao
                id={row.original.ID_Encomenda}
                cancelOrder={cancelOrderFunc}
              />
            );
          } else {
            return <div />;
          }
        },
        resizable: false
      }
    ];

    return columns;
  }, []);

  return (
    <Row>

      <Col xs="12" sm="12" md="12" lg="12">
        <Row style={{ textAlign: "left" }}>
          <Col xs="12" sm="12" md="12" lg="12">
            <ReactTable
              className="-highlight tableReact"
              data={orders}
              expanded={expanded}
              onExpandedChange={(newExpanded, index, event) =>
                handleRowExpanded(index)
              }
              getTheadProps={(state, rowInfo, column) => {
                return {
                  style: {
                    fontWeight: Configs.myIHTP.tableConfig.fontHeightHeader,
                    fontSize: Configs.myIHTP.tableConfig.fontSizeHeader,
                    height: Configs.myIHTP.tableConfig.heightHeader,
                    color: Configs.myIHTP.tableConfig.colorHeader,
                    backgroundColor:
                      Configs.myIHTP.tableConfig.backgroundColorHeader
                  }
                };
              }}
              getPaginationProps={state => {
                return {
                  style: {
                    color: Configs.myIHTP.tableConfig.colorHeader,
                    backgroundColor:
                      Configs.myIHTP.tableConfig.backgroundColorHeader,
                    fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody
                  }
                };
              }}
              getTrProps={(state, rowInfo, column, instance) => {
                var id_encomenda = null;
                var id_cliente = null;
                if (rowInfo) {
                  id_encomenda = rowInfo.original.ID_Encomenda;
                  id_cliente = rowInfo.original.ID_Cliente;
                }

                return {
                  onClick: getOrderDetailsData(id_encomenda, id_cliente),
                  style: {
                    "text-align": "center",
                    "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody,
                    background:
                      (rowInfo && rowInfo.row.ID_EstadoEncomenda === "AP"
                        ? Configs.myIHTP.tableConfig.encomendas
                          .backgroundColor.yellow
                        : null) ||
                      (rowInfo &&
                        (rowInfo.row.ID_EstadoEncomenda === "PA" ||
                          rowInfo.row.ID_EstadoEncomenda === "PR" ||
                          rowInfo.row.ID_EstadoEncomenda === "EC")
                        ? Configs.myIHTP.tableConfig.encomendas
                          .backgroundColor.green
                        : null) ||
                      (rowInfo &&
                        (rowInfo.row.ID_EstadoEncomenda === "CC" ||
                          rowInfo.row.ID_EstadoEncomenda === "PC")
                        ? Configs.myIHTP.tableConfig.encomendas
                          .backgroundColor.red
                        : null) ||
                      (rowInfo && rowInfo.row.ID_EstadoEncomenda === "FE"
                        ? Configs.myIHTP.tableConfig.encomendas
                          .backgroundColor.cyan
                        : null)
                  }
                };
              }}
              getTdProps={(state, rowInfo, column, instance) => {
                return {
                  style: {
                    "text-align": "center",
                    "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody
                  }
                };
              }}
              defaultSorted={[
                {
                  id: "ID_Encomenda",
                  desc: true
                }
              ]}
              collapseOnDataChange={
                Configs.myIHTP.tableConfig.collapseOnDataChange
              }
              defaultPageSize={20}
              showPagination={
                orders.length >
                  Configs.myIHTP.tableConfig.defaultPageSize
                  ? Configs.myIHTP.tableConfig.showPagination
                  : false
              }
              previousText={Configs.myIHTP.tableConfig.buttonCopyAnterior}
              nextText={Configs.myIHTP.tableConfig.buttonCopyProximo}
              noDataText={Configs.myIHTP.tableConfig.noDataText}
              pageText={Configs.myIHTP.tableConfig.pageText}
              ofText={Configs.myIHTP.tableConfig.ofText}
              rowsText={intl.formatMessage({ id: "table.config.rowsText" })}
              columns={columns}
              SubComponent={row => {

                return (
                  <OrderDetail
                    loading={loadingDetails}
                    orderId={row.original.ID_Encomenda}
                    orderDate={details.DataEncomenda}
                    state={details.EstadoEncomenda}
                    transportTypeDescription={details.DescricaoTipoTransporte}
                    paymentTypeId={details.ID_TipoPagamento}
                    paymentTypeDescription={details.DescricaoTipoPagamento}
                    paymentDate={details.DataPagamento}
                    sentDate={details.DataEnvio}
                    address={getShippingAddress()}
                    billingAddress={{
                      address: details.moradaFaturacaoMorada,
                      addressName: details.moradaFaturacaoNome,
                      vat: details.moradaFaturacaoNif,
                      postalCode: details.moradaFaturacaoCodigoPostal,
                      location: details.moradaFaturacaoLocalidade,
                      country: details.moradaFaturacaoPais
                    }}
                    currency={details.ID_Moeda}
                    totalPrice={details.PrecoTotal}
                    totalDiscount={details.DescontoTotal}
                    totalTax={details.IvaTotal}
                    transportCost={details.CustoTransporte}
                    userType={user.userType}
                    creditDiscount={details.DescontoCredito}
                    finalPrice={details.PrecoFinal}
                    personalNetPoints={details.NetPointsPessoal}
                    notes={details.Observacao}
                    items={itens.map(i => {
                      return {
                        id: i.Id,
                        description: i.Descricao,
                        taxPercentage: i.TaxaIva,
                        quantity: i.Quantidade,
                        unitPriceWithoutTax: i.PrecoUnitarioSemIva,
                        unitPriceWithTax: i.PrecoUnitarioComIva
                      }
                    })}
                    states={getStates()}
                    mbEntity={details.EntidadeMB}
                    mbReference={details.ReferenciaMB}
                  />
                );
              }}
            />
          </Col>
        </Row>
      </Col >
    </Row >
  );
}