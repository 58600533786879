import { TextField } from "@material-ui/core";
import { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { getOrderDetailsBackOffice, getOrdersBackOffice, insertCreditNote } from "../../../../../actions/myihtpActions";
// import { refund } from "../../../../../Analytics/DataLayer";
import Configs from "../../../../../helpers/Configs";
import Toast from "../../../../../helpers/Toast";
import useCurrencies from "../../../../../hooks/useCurrencies";
import useUser from "../../../../../hooks/useUser";
import Label, { Type } from "../../../../Common/Label";
import OrderDetail, { OrderAddress, OrderState } from "../../../../Common/Order/OrderDetail";
import { OrderDetails, OrderProductsDetails } from "../Dashboard/UltimasEncomendas/UltimasEncomendas";
import CancelarEncomendaBotao from "../Encomendas/CancelarEncomendaBotao";
import "../Encomendas/Encomendas.css";
import FaturaBotao from "../Encomendas/FaturaBotao";
import { cancelOrderBackOffice } from "../Encomendas/Helper";
import NotaCreditoBotao from "../Encomendas/NotaCreditoBotao";
import PagamentoBotao from "../Encomendas/PagamentoBotao";
import ReciboBotao from "../Encomendas/ReciboBotao";

const estadosConcluido = ['PR', 'EC', 'DO', 'EV', 'FE'];
export default function EncomendasBackoffice() {
  const [orders, setOrders] = useState<[]>([]);
  const [details, setDetails] = useState<OrderDetails>({} as OrderDetails);
  const [itens, setItens] = useState<OrderProductsDetails[]>([]);
  const [estados, setEstados] = useState<[]>([]);
  const [expanded, setExpanded] = useState({});
  const [loadingDetails, setLoadingDetails] = useState<boolean>(false);
  const [recordsPerPage, setRecordsPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [orderField, setOrderField] = useState<string>();
  const [orderFieldAsc, setOrderFieldAsc] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [successGetOrders, setSuccessGetOrders] = useState<boolean>(true);
  const [successGetOrderDetails, setSuccessGetOrderDetails] = useState<boolean>(true);
  const [searchOrderId, setSearchOrderId] = useState<number>();

  const currencies = useCurrencies();
  const intl = useIntl();
  const user = useUser();

  const columns = useMemo(() => {
    const columns = [
      {
        Header:
          Configs.myIHTP.tableConfig.encomendas.columns.idEncomenda.Header,
        accessor:
          Configs.myIHTP.tableConfig.encomendas.columns.idEncomenda.accessor,
        width: 150
      },
      {
        Header: Configs.myIHTP.tableConfig.encomendas.columns.idCliente.Header,
        accessor:
          Configs.myIHTP.tableConfig.encomendas.columns.idCliente.accessor,
        width: 300,
        Cell: row => {
          return (
            <span>
              {row.original.ID_Cliente} - {row.original.NomeCompleto}
            </span>
          );
        }
      },
      {
        Header:
          Configs.myIHTP.tableConfig.encomendas.columns.idEstadoEncomenda
            .Header,
        accessor:
          Configs.myIHTP.tableConfig.encomendas.columns.idEstadoEncomenda
            .accessor,
        show:
          Configs.myIHTP.tableConfig.encomendas.columns.idEstadoEncomenda.show,
        width: 250
      },
      {
        Header:
          Configs.myIHTP.tableConfig.encomendas.columns.estadoEncomenda.Header,
        accessor:
          Configs.myIHTP.tableConfig.encomendas.columns.estadoEncomenda
            .accessor,
        show: !isMobile,
        width: 250
      },
      {
        Header:
          Configs.myIHTP.tableConfig.encomendas.columns.dataEncomenda.Header,
        accessor:
          Configs.myIHTP.tableConfig.encomendas.columns.dataEncomenda.accessor,
        show: !isMobile,
        width: 150,
        Cell: row => {
          return row.original.DataEncomenda ? (
            <FormattedDate value={new Date(row.original.DataEncomenda)} />
          ) : (
            "-"
          );
        }
      },
      {
        Header: Configs.myIHTP.tableConfig.encomendas.columns.precoFinal.Header,
        accessor:
          Configs.myIHTP.tableConfig.encomendas.columns.precoFinal.accessor,
        width: 124,
        Cell: row => {
          const selectedCurrency = currencies.list?.find(a => a.ID_Moeda === row.original.ID_Moeda);

          return (
            <Label
              text={row.original.PrecoFinal.toFixed(2)}
              labelType={{
                type: Type.currency,
                currency: selectedCurrency?.Descricao ?? "€"
              }} />
          );
        }
      },
      {
        Header: "F",
        accessor: "fatura",
        width: 50,
        Cell: row => {
          if (row.original.UrlFatura) {
            return (
              <div>
                <FaturaBotao url={row.original.UrlFatura} />
              </div>
            );
          } else {
            return <div />;
          }
        },
        resizable: false
      },
      {
        Header: "R",
        accessor: "recibo",
        width: 50,
        Cell: row => {
          if (row.original.UrlRecibo) {
            return <ReciboBotao url={row.original.UrlRecibo} />;
          } else {
            return <div />;
          }
        },
        resizable: false
      },
      {
        Header: "NC",
        accessor: "notaCredito",
        width: 50,
        Cell: row => {
          if (row.original.UrlNotaCredito) {
            return <NotaCreditoBotao url={row.original.UrlNotaCredito} />;
          } else {
            return <div />;
          }
        },
        resizable: false
      },
      {
        Header: "P",
        accessor: "pagamento",
        width: 50,
        Cell: row => {
          if (
            !row.original.UrlFatura &&
            !row.original.UrlRecibo &&
            !row.original.NotaCredito
          ) {
            return <PagamentoBotao hash={row.original.HashEncomenda} />;
          } else {
            return <div />;
          }
        },
        resizable: false
      },
      {
        Header: "C",
        accessor: "cancelarEncomenda",
        width: 50,
        Cell: row => {
          if (row.original.ID_EstadoEncomenda === "AP" || estadosConcluido.includes(row.original.ID_EstadoEncomenda) == true) {
            return (
              <CancelarEncomendaBotao
                id={row.original.ID_Encomenda}
                estado={row.original.ID_EstadoEncomenda}
                cancelOrder={cancelOrder}
              />
            );
          } else {
            return <div />;
          }
        },
        resizable: false
      }
    ]

    return columns;
  }, []);

  useEffect(() => {
    loadOrders(currentPage, recordsPerPage, orderField, orderFieldAsc, searchOrderId);
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      loadOrders(currentPage, recordsPerPage, orderField, orderFieldAsc, searchOrderId);
    }, 500)

    return () => clearTimeout(timer)
  }, [searchOrderId])


  const loadOrders = (page: number, pageSize: number, orderField, orderFieldAsc, orderId?: number) => {
    setLoading(true);

    getOrdersBackOffice(page, pageSize, orderField, orderFieldAsc, orderId)
      .then(data => {
        setLoading(false);

        if (data.success === true) {
          setTotalPages(Math.ceil(data.obj.total / pageSize));
          setCurrentPage(page);
          setRecordsPerPage(pageSize);
          setOrders(data.obj.dados);
          setOrderField(orderField);
          setOrderFieldAsc(orderFieldAsc);
          setSuccessGetOrders(true);
        } else {
          setTotalPages(0);
          setCurrentPage(0);
          setRecordsPerPage(pageSize);
          setOrders([]);
          setOrderField(orderField);
          setOrderFieldAsc(orderFieldAsc);
          setSuccessGetOrders(false);
        }
      }).catch(err => {
        setTotalPages(0);
        setCurrentPage(0);
        setRecordsPerPage(pageSize);
        setOrders([]);
        setOrderField(orderField);
        setOrderFieldAsc(orderFieldAsc);
        setSuccessGetOrders(false);
        setLoading(false);
      });
  }

  //CANCELAR ENCOMENDA
  const cancelOrder = (id, estado) => {
    var confirm = window.confirm(intl.formatMessage({ id: "myihtp.encomenda.cancelar" }));

    if (confirm) {
      //Encomendas que aguardam pagamento
      if (estado == "AP") {
        cancelOrderBackOffice(id).then(result => {
          handleResultCancelOrder(id, result);
        });
        return;
      }

      //Se for uma encomenda concluida, então vai fazer o pedido para criar a nota de crédito
      if (estadosConcluido.includes(estado)) {
        insertCreditNote(id).then(data => {
          handleResultCancelOrder(id, data);
        });
        return;
      }
    }
  };

  const handleResultCancelOrder = (id, result) => {
    if (result.success === true) {
      //Cancela no GA4    
      // refund(id);

      //Vamos buscar as encomendas atualizadas
      loadOrders(currentPage, recordsPerPage, orderField, orderFieldAsc, searchOrderId);

      Toast.Show("success", result.message);
    } else {
      Toast.Show("error", result.message);
    }
  }

  const handleRowExpanded = (index) => {
    setExpanded({ [index[0]]: !expanded[index[0]] })
  }

  const getOrderDetailsData = (id: number) => async e => {

    //Se tentarmos abrir uma encomenda que já está aberta, não vamos carregar a informação
    if (id == details.ID_Encomenda) {
      return;
    }

    setLoadingDetails(true);

    getOrderDetailsBackOffice(id)
      .then(data => {
        setLoadingDetails(false);

        if (data.success === true) {
          setDetails(data.obj.Detalhes[0]);
          setItens(data.obj.Itens);
          setEstados(data.obj.Estados);
          setSuccessGetOrderDetails(true);
        } else {
          setSuccessGetOrderDetails(false);
        }
      }).catch(error => {
        setLoadingDetails(false);
        setSuccessGetOrderDetails(false);
      });
  };

  const fetchNewInfo = (page: number, pageSize: number, orderField, orderFieldAsc) => {

    if (pageSize !== recordsPerPage) page = 0;
    if (orderField !== orderField) page = 0;
    if (orderFieldAsc !== orderFieldAsc) page = 0;

    loadOrders(page, pageSize, orderField, orderFieldAsc, searchOrderId);
  }

  const getStates = (): OrderState[] => {
    if (estados == null || estados.length <= 0) return [];

    const result = estados.map((e: any) => {
      return {
        date: e.DataMudancaEstado,
        status: e.EstadoEncomenda,
        notes: e.Observacao,
        sentEmail: e.EnviadoMail
      }
    })

    return result;
  }

  const getShippingAddress = (): OrderAddress | undefined => {
    if (details.moradaEnvioMorada == null) return;

    return {
      address: details.moradaEnvioMorada,
      addressName: details.moradaEnvioNome,
      postalCode: details.moradaEnvioCodigoPostal,
      location: details.moradaEnvioLocalidade,
      country: details.moradaEnvioPais
    }
  }

  return (
    <Row>
      <Col xs="12" sm="12" md="12" lg="12">

        {/* Input para colocar o id da encomenda que se pretende buscar*/}
        <Row style={{ textAlign: "right", alignItems: "center" }}>
          <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "right", display: "flex", alignItems: "center" }}>
            <span style={{ minHeight: "100%" }}>
              <FormattedMessage id="myihtp.encomendas.ID_Encomenda" />: &nbsp;
            </span>
            <TextField variant="outlined"
              onChange={(e: any) => setSearchOrderId(e.target.value)}
              name="searchOrderId"
              value={searchOrderId}
              id="searchOrderId" />
          </Col>
        </Row>

        <br />
        {successGetOrders === false ?
          <Row>
            <Col xs="12" sm="12" md="12" lg="12">
              <span style={{ color: "red" }}> <FormattedMessage id="myihtp.encomendas.ErroObterEncomendas" /></span>
            </Col>
          </Row>
          : null}

        {/* Resultados da Pesquisa*/}
        <Row style={{ textAlign: "left" }}>
          <Col xs="12" sm="12" md="12" lg="12">

            <br />
            <ReactTable
              className="-highlight tableReact"
              data={orders}
              expanded={expanded}
              filtered={[]}
              loading={loading}
              onExpandedChange={(newExpanded, index, event) =>
                handleRowExpanded(index)
              }
              getTheadProps={(state, rowInfo, column) => {
                return {
                  style: {
                    fontWeight: Configs.myIHTP.tableConfig.fontHeightHeader,
                    fontSize: Configs.myIHTP.tableConfig.fontSizeHeader,
                    height: Configs.myIHTP.tableConfig.heightHeader,
                    color: Configs.myIHTP.tableConfig.colorHeader,
                    backgroundColor:
                      Configs.myIHTP.tableConfig.backgroundColorHeader
                  }
                };
              }}
              getPaginationProps={state => {
                return {
                  style: {
                    color: Configs.myIHTP.tableConfig.colorHeader,
                    backgroundColor:
                      Configs.myIHTP.tableConfig.backgroundColorHeader,
                    fontSize: Configs.myIHTP.tableConfig.fontSizeRowBody
                  }
                };
              }}
              getTrProps={(state, rowInfo, column, instance) => {
                var id_encomenda = 0;
                if (rowInfo) {
                  id_encomenda = rowInfo.original.ID_Encomenda;
                }

                return {
                  onClick: getOrderDetailsData(id_encomenda),
                  style: {
                    "text-align": "center",
                    "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody,
                    background:
                      (rowInfo && rowInfo.row.ID_EstadoEncomenda === "AP"
                        ? Configs.myIHTP.tableConfig.encomendas
                          .backgroundColor.yellow
                        : null) ||
                      (rowInfo &&
                        (rowInfo.row.ID_EstadoEncomenda === "PA" ||
                          rowInfo.row.ID_EstadoEncomenda === "PR" ||
                          rowInfo.row.ID_EstadoEncomenda === "EC")
                        ? Configs.myIHTP.tableConfig.encomendas
                          .backgroundColor.green
                        : null) ||
                      (rowInfo &&
                        (rowInfo.row.ID_EstadoEncomenda === "CC" ||
                          rowInfo.row.ID_EstadoEncomenda === "PC")
                        ? Configs.myIHTP.tableConfig.encomendas
                          .backgroundColor.red
                        : null) ||
                      (rowInfo && rowInfo.row.ID_EstadoEncomenda === "FE"
                        ? Configs.myIHTP.tableConfig.encomendas
                          .backgroundColor.cyan
                        : null)
                  }
                };
              }}
              getTdProps={(state, rowInfo, column, instance) => {
                return {
                  style: {
                    "text-align": "center",
                    "font-size": Configs.myIHTP.tableConfig.fontSizeRowBody
                  }
                };
              }}
              defaultSorted={[
                {
                  id: "ID_Encomenda",
                  desc: true
                }
              ]}
              collapseOnDataChange={
                Configs.myIHTP.tableConfig.collapseOnDataChange
              }
              pageSizeOptions={[5, 10, 20]}
              defaultPageSize={10}
              showPageJump={false}
              page={currentPage}
              pages={totalPages}
              pageSize={recordsPerPage}
              showPagination={true}
              previousText={Configs.myIHTP.tableConfig.buttonCopyAnterior}
              nextText={Configs.myIHTP.tableConfig.buttonCopyProximo}
              noDataText={Configs.myIHTP.tableConfig.noDataText}
              pageText={Configs.myIHTP.tableConfig.pageText}
              ofText={Configs.myIHTP.tableConfig.ofText}
              rowsText={intl.formatMessage({ id: "table.config.rowsText" })}
              columns={columns}
              manual
              onFetchData={(state, instance) => {
                fetchNewInfo(state.page, state.pageSize, state.sorted[0].id, !state.sorted[0].desc);
              }}
              SubComponent={row => {

                return (
                  <OrderDetail
                    loading={loadingDetails}
                    errorLoading={!successGetOrderDetails}
                    orderId={row.original.ID_Encomenda}
                    orderDate={details.DataEncomenda}
                    state={details.EstadoEncomenda}
                    transportTypeDescription={details.DescricaoTipoTransporte}
                    paymentTypeId={details.ID_TipoPagamento}
                    paymentTypeDescription={details.DescricaoTipoPagamento}
                    paymentDate={details.DataPagamento}
                    sentDate={details.DataEnvio}
                    address={getShippingAddress()}
                    billingAddress={{
                      address: details.moradaFaturacaoMorada,
                      addressName: details.moradaFaturacaoNome,
                      vat: details.moradaFaturacaoNif,
                      postalCode: details.moradaFaturacaoCodigoPostal,
                      location: details.moradaFaturacaoLocalidade,
                      country: details.moradaFaturacaoPais
                    }}
                    currency={details.ID_Moeda}
                    totalPrice={details.PrecoTotal}
                    totalDiscount={details.DescontoTotal}
                    totalTax={details.IvaTotal}
                    transportCost={details.CustoTransporte}
                    userType={user.userType}
                    creditDiscount={details.DescontoCredito}
                    finalPrice={details.PrecoFinal}
                    personalNetPoints={details.NetPointsPessoal}
                    notes={details.Observacao}
                    items={itens.map(i => {
                      return {
                        id: i.Id,
                        description: i.Descricao,
                        taxPercentage: i.TaxaIva,
                        quantity: i.Quantidade,
                        unitPriceWithoutTax: i.PrecoUnitarioSemIva,
                        unitPriceWithTax: i.PrecoUnitarioComIva
                      }
                    })}
                    states={getStates()}
                    mbEntity={details.EntidadeMB}
                    mbReference={details.ReferenciaMB}
                  />
                );
              }}
            />

          </Col>
        </Row>
      </Col>
    </Row >
  );
}